import {
    GET_ALL_TASK_DASHBOARD,
    ADD_CS_TASK,
    UPDATE_CS_TASK,
    GET_ALL_ADMIN_LIST,
    GET_CS_TASK
} from '../actions/task_dashboard_actions';

function TaskDashboardReducers(state = {
    get_all_task_dashboard_data: [],
    add_cs_task: [],
    update_cs_task: [],
    get_all_admin_list: [],
    get_cs_task: []
}, action) {

    switch (action.type) {
        case GET_ALL_TASK_DASHBOARD:
            return Object.assign({}, state, {
                get_all_task_dashboard_data: action.get_all_task_dashboard_data,
            });

        case ADD_CS_TASK:
            return Object.assign({}, state, {
                add_cs_task: action.add_cs_task,
            });

        case UPDATE_CS_TASK:
            return Object.assign({}, state, {
                update_cs_task: action.update_cs_task,
            });

        case GET_ALL_ADMIN_LIST:
            return Object.assign({}, state, {
                get_all_admin_list: action.get_all_admin_list,
            });

        case GET_CS_TASK:
            return Object.assign({}, state, {
                get_cs_task: action.get_cs_task,
            });
        
        default:
            return state;
    }
}
    
export default TaskDashboardReducers