import {
    GET_VOICE_CALL_TOKEN,
    SHOW_VOICE_CALL_POPUP,
    STORE_ORDER_DETAILS,
    TWILIO_NUMBERS_LIST,
    VOICE_CALL_ONGOING
} from '../actions/voice_call_actions';

function VoiceCallReducers(state = {
    get_voice_call_token: [],
    show_voice_call_popup: false,
    order_details: [],
    calling_number: '',
    call_type: '',
    twillio_numbers_list: [],
    voice_call_ongoing: false
}, action) {

    switch (action.type) {
        case TWILIO_NUMBERS_LIST:
            return Object.assign({}, state, {
                twillio_numbers_list: action.twillio_numbers_list,
            });
        
        case GET_VOICE_CALL_TOKEN:
            return Object.assign({}, state, {
                get_voice_call_token: action.get_voice_call_token,
            });
        
        case SHOW_VOICE_CALL_POPUP:
            return Object.assign({}, state, {
                show_voice_call_popup: action.show_voice_call_popup,
            });

        case STORE_ORDER_DETAILS:
            return Object.assign({}, state, {
                order_details: action.order_details,
                calling_number: action.calling_number,
                call_type: action.call_type,
            });

        case VOICE_CALL_ONGOING:
            return Object.assign({}, state, {
                voice_call_ongoing: action.voice_call_ongoing
            });
        
        default:
            return state;
    }
}
    
export default VoiceCallReducers;