import Axios from 'axios';
var config = require("../../utils/generalconfig.json");

export const GET_ALL_TASK_DASHBOARD = 'GET_ALL_TASK_DASHBOARD'
export const ADD_CS_TASK = 'ADD_CS_TASK'
export const UPDATE_CS_TASK = 'UPDATE_CS_TASK'
export const GET_ALL_ADMIN_LIST = 'GET_ALL_ADMIN_LIST'
export const GET_CS_TASK = 'GET_CS_TASK'

let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

// Get All Task Dashboard
export function getAllTaskDashboardData(post) {
    return dispatch => {
        return Axios.post('api/get_all_cs_task', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetAllTaskDashboard(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetAllTaskDashboard(response) {
    return {
        type: GET_ALL_TASK_DASHBOARD,
        get_all_task_dashboard_data: response
    }
}

  // end all task dasboard

// Add CS Task

export function addCSTaskData(post) {
    return dispatch => {
        return Axios.post('api/add_cs_task/', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(insertAddCSTask(response.data))
            } else {
                dispatch(insertAddCSTask([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function insertAddCSTask(response) {
    return {
        type: ADD_CS_TASK,
        add_cs_task: response
    }
}

export function emptyAddCSTask(){
    return (dispatch) => {
        dispatch( {
            type: ADD_CS_TASK,
            add_cs_task: []
        })
    }
}

// end cs task

// update CS Task
export function updateCSTaskData(post, id) {
    return dispatch => {
        return Axios.post('api/update_cs_task/'+id, post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(insertUpdateCSTask(response.data))
            } else {
                dispatch(insertUpdateCSTask([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function insertUpdateCSTask(response) {
    return {
        type: UPDATE_CS_TASK,
        update_cs_task: response
    }
}

export function emptyUpdateCSTask(){
    return (dispatch) => {
        dispatch( {
            type: UPDATE_CS_TASK,
            update_cs_task: []
        })
    }
}

// end cs task

// get all admin list data

export function getAllAdminListData() {
    return dispatch => {
        return Axios.get('api/get_all_admin_list/', { baseURL: baseurl })
        .then(response => {
            if (response.data !== "") {
                dispatch(requestAdminList(response.data))
            } else {
                dispatch(requestAdminList([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function requestAdminList(response) {
    return {
        type: GET_ALL_ADMIN_LIST,
        get_all_admin_list: response
    }
}

// end get all admin list data


// update CS Task
export function getCSTaskData(post) {
    return dispatch => {
        return Axios.post('api/get_cs_task/', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(getCSTask(response.data))
            } else {
                dispatch(getCSTask([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function getCSTask(response) {
    return {
        type: GET_CS_TASK,
        get_cs_task: response
    }
}

export function emptyGetCSTask(){
    return (dispatch) => {
        dispatch( {
            type: GET_CS_TASK,
            get_cs_task: []
        })
    }
}

// end cs task