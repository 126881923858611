import Axios from 'axios';
var config = require("../../utils/generalconfig.json");

export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const LOGOUT = 'LOGOUT'
export const SELLER_DETAILS = 'SELLER_DETAILS'
export const SELLER_STATUS = 'SELLER_STATUS'
export const DASHBOARD_DETAILS = 'DASHBOARD_DETAILS'
export const SERVICE_PRICE = 'SERVICE_PRICE'
export const SERVICE_DETAILS = 'SERVICE_DETAILS'
export const BUYER_DETAILS = 'BUYER_DETAILS';
export const CAPTURE_EMAILS = 'CAPTURE_EMAILS';
export const FILTER_LOGS = 'FILTER_LOGS';
export const QUOTE_CALC_LOGS = 'QUOTE_CALC_LOGS';
export const GET_ALLCOUNTRY = 'GET_ALLCOUNTRY';
export const GET_STATE_BY_COUNTRY = 'GET_STATE_BY_COUNTRY';
export const GET_All_CATEGORY = 'GET_All_CATEGORY';
export const SELLER_SEQUENCE = 'SELLER_SEQUENCE';
export const SELLER_LIVE_STATUS = 'SELLER_LIVE_STATUS';
export const UPDATE_SELLER_STATUS = 'UPDATE_SELLER_STATUS';
export const ORDER_LIST = 'ORDER_LIST';
export const ORDER_STATUS_LIST = 'ORDER_STATUS_LIST';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const INSERT_ARTIST_PAYMENT = 'INSERT_ARTIST_PAYMENT';
export const UPDATE_ARTIST_PAYMENT = 'UPDATE_ARTIST_PAYMENT';
export const UPDATE_SELLER_PAYMENT = 'UPDATE_SELLER_PAYMENT';
export const DELETE_ARTIST_PAYMENT = 'DELETE_ARTIST_PAYMENT';
export const UPDATE_BUYER_DETAILS = 'UPDATE_BUYER_DETAILS';
export const UPLOAD_FILE_S3UPLOAD = 'UPLOAD_FILE_S3UPLOAD';
export const UPDATE_ARTIST_RECEIPT = 'UPDATE_ARTIST_RECEIPT';
export const UPDATE_SELLER_VISIBLE = 'UPDATE_SELLER_VISIBLE';
export const UPDATE_QUOTE_CALC_VISIBLE = 'UPDATE_QUOTE_CALC_VISIBLE';
export const CITY_CONTENT = 'CITY_CONTENT'
export const UPDATE_SEARCH_PAGE_CONTENT = 'UPDATE_SEARCH_PAGE_CONTENT'
export const SELLER_USING_ONE_BUYER = 'SELLER_USING_ONE_BUYER'
export const UPDATE_LOST_STATUS = 'UPDATE_LOST_STATUS'
export const TAG_DEATILS = 'TAG_DEATILS'
export const UPDATE_TAG = 'UPDATE_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const ADD_TAG = 'ADD_TAG'
export const UPDATE_ORDER_COMMISSION = 'UPDATE_ORDER_COMMISSION'
export const INSERT_BUYER_PAYMENT = 'INSERT_BUYER_PAYMENT'
export const UPDATE_BUYER_PAYMENT = 'UPDATE_BUYER_PAYMENT'
export const DELETE_BUYER_PAYMENT = 'DELETE_BUYER_PAYMENT'
export const EDIT_ARTIST_EMAIL = 'EDIT_ARTIST_EMAIL'
export const UPDATE_ARTIST_NOTE = 'UPDATE_ARTIST_NOTE'
export const HARD_DEL_SELLER = 'HARD_DEL_SELLER'
export const UPDATE_ACTIVE_STATUS = 'UPDATE_ACTIVE_STATUS'
export const JOBS_LIST = 'JOBS_LIST'
export const INSERT_ORDER_NOTES = 'INSERT_ORDER_NOTES'
export const REVIEW_LIST = 'REVIEW_LIST'
export const UPDATE_JOB_DETAILS = 'UPDATE_JOB_DETAILS'
export const CITY_LIST_ACTIVE = 'CITY_LIST_ACTIVE'
export const SELLER_LIST_FULL_PROGRESS = 'SELLER_LIST_FULL_PROGRESS'
export const INSERT_SEARCH_PAGE_CONTENT = 'INSERT_SEARCH_PAGE_CONTENT'
export const SEARCH_PAGE_CONTENT = 'SEARCH_PAGE_CONTENT'
export const UPDATE_SEARCH_PAGE_DYNAMIC = 'UPDATE_SEARCH_PAGE_DYNAMIC'
export const FAQ_CONTENT = 'FAQ_CONTENT'
export const CREATE_COUNTRY = 'CREATE_COUNTRY'
export const CREATE_STATE = 'CREATE_STATE'
export const UPDATE_COUNTRY_DETAILS = 'UPDATE_COUNTRY_DETAILS'
export const UPDATE_STATE_DETAILS = 'UPDATE_STATE_DETAILS'
export const CREATE_BANK_DETAILS = 'CREATE_BANK_DETAILS'
export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS'
export const GET_ALL_BANK_DETAILS = 'GET_ALL_BANK_DETAILS'
export const SELLER_ALL_DETAILS = 'SELLER_ALL_DETAILS'
export const UPDATE_MULTIPLE_ORDER_STATUS = 'UPDATE_MULTIPLE_ORDER_STATUS'
export const BUYER_LIMIT_DETAILS = 'BUYER_LIMIT_DETAILS'

export const ORDER_DETAILS_BY_ID = 'ORDER_DETAILS_BY_ID'
export const ORDER_CONVERSATION_BY_ID = 'ORDER_CONVERSATION_BY_ID'
export const OTHER_ORDER_DETAILS = 'OTHER_ORDER_DETAILS'
export const ADD_BUYER_NOTES = 'ADD_BUYER_NOTES'
export const SEND_PAYMENT_EMAIL = 'SEND_PAYMENT_EMAIL'
export const QUOTE_USING_ORDER = 'QUOTE_USING_ORDER'
export const INVOICE_FROM_DASHBOARD = 'INVOICE_FROM_DASHBOARD'
export const GET_ORDER_BY_DETAILS = 'GET_ORDER_BY_DETAILS'
export const INSERT_INVOICE_DATA = 'INSERT_INVOICE_DATA'
export const INSERT_MULTIPLE_INVOICE_DATA = 'INSERT_MULTIPLE_INVOICE_DATA'
export const UPLOAD_INVOICE_S3 = 'UPLOAD_INVOICE_S3'
export const JOB_DETAILS = 'JOB_DETAILS'
export const ORDER_DETAILS = 'ORDER_DETAILS'
export const ORDER_CONVERSATION_WITH_ANALYTICS = 'ORDER_CONVERSATION_WITH_ANALYTICS'
export const UPDATE_CS_STATUS = 'UPDATE_CS_STATUS'
export const CS_CONVERSATION_READ_STATUS = 'CS_CONVERSATION_READ_STATUS'
export const CS_CONVERSATION_UNREAD_STATUS = 'CS_CONVERSATION_UNREAD_STATUS'
export const GET_REPORTING = 'GET_REPORTING'
export const GET_ALL_FLAG_DETAILS = 'GET_ALL_FLAG_DETAILS'
export const ADD_FLAG_ORDER_DETAILS = 'ADD_FLAG_ORDER_DETAILS'
export const UPDATE_FLAG_ORDER_DETAILS = 'UPDATE_FLAG_ORDER_DETAILS'
export const UPDATE_CS_FOLLOW_UP = 'UPDATE_CS_FOLLOW_UP'
export const GET_CITY_LAT_LONG = 'GET_CITY_LAT_LONG'
export const UPDATE_CS_CUSTOMER_TYPE = 'UPDATE_CS_CUSTOMER_TYPE'
export const UPDATE_SELLER_NOTES = 'UPDATE_SELLER_NOTES'
export const SELLER_EXPERIENCE_LIST = 'SELLER_EXPERIENCE_LIST'
export const ORDER_CALL_RECORDING = 'ORDER_CALL_RECORDING'
export const All_ORDER_CALL_RECORDING_LIST = 'All_ORDER_CALL_RECORDING_LIST'
export const SELLER_BUYER_REVIEWS = 'SELLER_BUYER_REVIEWS'
export const UPDATE_CS_FOLLOW_UP_DATE = 'UPDATE_CS_FOLLOW_UP_DATE'
export const POJ_EMAIL_TRIGGER = 'POJ_EMAIL_TRIGGER'
export const ORDER_BASED_AUTO_LOGIN_LINK = 'ORDER_BASED_AUTO_LOGIN_LINK'
export const NOT_INTERESTED_ORDER_LIST = 'NOT_INTERESTED_ORDER_LIST'
export const UPDATE_SELLER_DETAILS_COMMON = 'UPDATE_SELLER_DETAILS_COMMON'
export const FLAG_ORDER_DETAILS_LIST = 'FLAG_ORDER_DETAILS_LIST'
export const ORDER_DETAILS_BY_BUYER = 'ORDER_DETAILS_BY_BUYER'
export const MARKED_AS_READ_MULTIPLE_ORDER = 'MARKED_AS_READ_MULTIPLE_ORDER'
export const GET_SINGLE_ORDER_DETAILS = 'GET_SINGLE_ORDER_DETAILS'
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const GET_EMAIL_TEMPLATE_LIST = 'GET_EMAIL_TEMPLATE_LIST'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const SEND_EMAIL_TEMPLATE_VIA_EMAIL = 'SEND_EMAIL_TEMPLATE_VIA_EMAIL'
export const GET_REVIEWS = 'GET_REVIEWS'
export const CONVERT_TO_POJ = 'CONVERT_TO_POJ'
export const UPDATE_MARKETING_NOTES = 'UPDATE_MARKETING_NOTES'
export const UPDATE_ORDER_CALL_FLAG = 'UPDATE_ORDER_CALL_FLAG';
export const UPDATE_SELLER_STRIPE_VISIBLE = 'UPDATE_SELLER_STRIPE_VISIBLE';
export const GET_EMAIL_LOGS = 'GET_EMAIL_LOGS';
export const GET_SEND_EMAIL_TEMPLATE_BY_ID = 'GET_SEND_EMAIL_TEMPLATE_BY_ID';
export const EXPORT_SELLER_ALL_DATA = 'EXPORT_SELLER_ALL_DATA';
export const DELETED_ARTIST_LIST = 'DELETED_ARTIST_LIST';
export const SPAM_ORDER_JOB_LIST = 'SPAM_ORDER_JOB_LIST';
export const UPDATE_SELLER_ROLE = 'UPDATE_SELLER_ROLE';
export const YATIN_MEETING_NOTIFICATION = 'YATIN_MEETING_NOTIFICATION';
export const CONVERT_TO_POJ_V2 = 'CONVERT_TO_POJ_V2';
export const HARD_DELETE_BUYER = 'HARD_DELETE_BUYER';
export const GET_TIP_INFORMATION = 'GET_TIP_INFORMATION'
export const COUPON_LIST = 'COUPON_LIST';
export const SINGLE_COUPON = 'SINGLE_COUPON';
export const NOTIFY_ARTIST_FOR_JOB = 'NOTIFY_ARTIST_FOR_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const HOLD_PAYMENT_LIST = 'HOLD_PAYMENT_LIST';
export const ALL_PAYMENT_LIST = 'ALL_PAYMENT_LIST';
export const SELLER_SUBSCRIPTION_DATA_LIST = 'SELLER_SUBSCRIPTION_DATA_LIST';
export const SET_SELLER_SUBSCRIPTION = 'SET_SELLER_SUBSCRIPTION';
export const UPDATE_BUYER_COMMON_DETAILS = 'UPDATE_BUYER_COMMON_DETAILS';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const ALL_SUBSCRIPTION_LIST = 'ALL_SUBSCRIPTION_LIST';
export const SEARCH_TERMS = 'SEARCH_TERMS';
export const CREATE_SEARCH_TERM_PAGE = 'CREATE_SEARCH_TERM_PAGE';
export const UPDATE_SEARCH_TERM_PAGE = 'UPDATE_SEARCH_TERM_PAGE';
export const JOB_LIVE_PUSH_NOTIFICATION = 'JOB_LIVE_PUSH_NOTIFICATION';
export const BUYER_SUBSCRIPTION_DATA_LIST = 'BUYER_SUBSCRIPTION_DATA_LIST';
export const ADD_PARTICIPANT_IN_CONVERSATION = 'ADD_PARTICIPANT_IN_CONVERSATION';
export const GET_USER_NOTIFICATION_SETTING = 'GET_USER_NOTIFICATION_SETTING';
export const SET_USER_NOTIFICATION_SETTING = 'SET_USER_NOTIFICATION_SETTING';
export const GET_EMAIL_NOTIFICATION_SETTING = 'GET_EMAIL_NOTIFICATION_SETTING';
export const SET_EMAIL_NOTIFICATION_SETTING = 'SET_EMAIL_NOTIFICATION_SETTING';
export const MANAGE_SELLER_BADGE = 'MANAGE_SELLER_BADGE';
export const COUNTER_OTP_REPORT = "COUNTER_OTP_REPORT";
export const QUITE_OTP_ORDER_REPORT = "QUITE_OTP_ORDER_REPORT";
export const ADD_QUITE_OTP = "ADD_QUITE_OTP";
export const REMOVE_QUITE_OTP = "REMOVE_QUITE_OTP";
export const GET_INVOICE_DETAIL_BY_INVOICE_COUNT = 'GET_INVOICE_DETAIL_BY_INVOICE_COUNT';
export const UPDATE_INVOICE_DETAIL = 'UPDATE_INVOICE_DETAIL';
export const GET_PAYMENT_TIMELINE = 'GET_PAYMENT_TIMELINE';
export const GIFT_CARD_LIST = 'GIFT_CARD_LIST';
export const REFERRAL_USERS_LIST = 'REFERRAL_USERS_LIST';
export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_MULTIPLE_INVOICE = 'ADD_MULTIPLE_INVOICE';
export const SELLER_DETAIL_FOR_INVOICE = 'SELLER_DETAIL_FOR_INVOICE';
export const DELETE_OFFER = 'DELETE_OFFER';
export const SELLER_ALL_DETAIL = 'SELLER_ALL_DETAIL';
export const SELLER_ORDER_DETAIL_LOGS = 'SELLER_ORDER_DETAIL_LOGS';
export const SELLER_PAYMENT_LOGS = 'SELLER_PAYMENT_LOGS';
export const SELLER_TRANSACTION_LOGS = 'SELLER_TRANSACTION_LOGS';
export const SELLER_NOTIFICATION_LOGS = 'SELLER_NOTIFICATION_LOGS';
export const SELLER_EXPERIENCE_LOGS = 'SELLER_EXPERIENCE_LOGS';
export const SELLER_VISIBILITY_LOGS = 'SELLER_VISIBILITY_LOGS';
export const SELLER_CS_LOGS = 'SELLER_CS_LOGS';
export const SELLER_ORDER_COUNT = 'SELLER_ORDER_COUNT';
export const MEMBER_SHIP_LOGS = 'MEMBER_SHIP_LOGS';
export const MIXPANEL_SELLER_DATA = 'MIXPANEL_SELLER_DATA';
export const GET_SELLER_COUNT_WITH_STATE_BY_COUNTRY = 'GET_SELLER_COUNT_WITH_STATE_BY_COUNTRY';
export const ASSIGN_SELLER_MEMBERSHIP = "ASSIGN_SELLER_MEMBERSHIP"
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';
export const ALL_BADGES_LIST = 'ALL_BADGES_LIST';
export const CHECK_NEAR_BY_TOP_ARTIST = 'CHECK_NEAR_BY_TOP_ARTIST';
export const COMMON_OTP_SELLER_DETAIL = 'COMMON_OTP_SELLER_DETAIL';
export const BAA_ASSISTANT_CONVERSATION = 'BAA_ASSISTANT_CONVERSATION';
export const CREATE_BAA_ASSISTANT_MESSAGE = 'CREATE_BAA_ASSISTANT_MESSAGE';
export const CANVAS_OTP_REPORT = 'CANVAS_OTP_REPORT';
export const CHECK_SELLER_CURRENT_MEMBERSHIP = 'CHECK_SELLER_CURRENT_MEMBERSHIP';
export const ALREADY_PAID_RESPONSE = 'ALREADY_PAID_RESPONSE';
export const ALREADY_PAID_RESPONSE_DATA = 'ALREADY_PAID_RESPONSE_DATA';
export const BUY_CANVAS_DATA = 'BUY_CANVAS_DATA';
export const MEMBER_SHIP_PROGRESS_TRACKING = 'MEMBER_SHIP_PROGRESS_TRACKING';
export const CTA_ORDER_LIST = 'CTA_ORDER_LIST';
export const SELLER_DASHBOARD_DETAILS = 'SELLER_DASHBOARD_DETAILS';
export const CHECK_STRIPE_STATUS = "CHECK_STRIPE_STATUS"
export const APPROVE_HOLD_ORDER = "APPROVE_HOLD_ORDER"
export const BAA_OPENAI_V1 = "BAA_OPENAI_V1";
export const AWS_BEDROCK_V1 = "AWS_BEDROCK_V1";
export const CREATE_BAA_COPILOT_MESSAGE = "CREATE_BAA_COPILOT_MESSAGE";
export const GET_BAA_COPILOT_MESSAGES = "GET_BAA_COPILOT_MESSAGES";
export const UPDATE_ORDER_CONVERSATION = "UPDATE_ORDER_CONVERSATION";
export const BUYER_ORDERS_COPILOT_ANALYSIS = "BUYER_ORDERS_COPILOT_ANALYSIS";
export const REMOVE_QUITE_OTP_V2 = "REMOVE_QUITE_OTP_V2";
export const BUYER_ORDER_CONVERSATION = "BUYER_ORDER_CONVERSATION";
export const BUYER_ORDER_CONVERSATION_BY_USER_ID = "BUYER_ORDER_CONVERSATION_BY_USER_ID";

let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
  baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
  baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
  baseurl = config.api_stage_url_v2
} 
else if (window.location.hostname === 'localhost') {
  baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
  let source = window.location.pathname
  if (source !== '/' && source !== '/logout') {
    source = '?source=' + source
  } else {
    source = ''
  }
  window.location.href = '/' + source
}


//////////////////////////////////////////////seller///////////////////////////////////

export function getSellerDetails(post) {

  return (dispatch) => {
    return dispatch(getSeller(post))
  }
}

function getSeller(post) {
  return dispatch => {
    // console.log('--api body ----------',{post})
      // return Axios.get('api/get_seller_details?page=' + post.page_number +"&limit=" + post.limit+"&search=" + post.search,{ baseURL: baseurl })
      return Axios.post('api/v2/get_seller_details' ,post,{ baseURL: baseurl })
          .then(response => {            
              if (response.data !== "") {
                  dispatch(requestSellerDetails(response.data))
              } else {
                  dispatch(requestSellerDetails([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    // dispatch(push('/'));
                    handleUnAuthorised();
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestSellerDetails(response) {
  return {
    type: SELLER_DETAILS,
    seller_details: response,
  }
}




//==================== Seller status =================== 

// ========== get all seller details ==============


export function getSellerAllDetails(post) {

  return (dispatch) => {
    return dispatch(getSellerAllData(post))
  }
}

function getSellerAllData(post) {
  return dispatch => {
    return Axios.get('api/get_seller_all_details', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSellerAllDetails(response.data))
        } else {
          dispatch(requestSellerAllDetails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSellerAllDetails(response) {

  return {
    type: SELLER_ALL_DETAILS,
    seller_all_details: response,
  }
}





// ========== end seller details ================

export function getSellerStatus() {

  return (dispatch) => {
    return dispatch(GetStatus())
  }
}

function GetStatus() {
  return dispatch => {
    return Axios.get('api/get_seller_status', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSellerStatus(response.data))
        } else {
          dispatch(requestSellerStatus([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSellerStatus(response) {

  return {
    type: SELLER_STATUS,
    seller_status: response,
  }
}

//============================

export function getServiceDetails(id) {

  return (dispatch) => {
    return dispatch(getServiceDe(id))
  }
}

function getServiceDe(id) {

  return dispatch => {
    return Axios.get('api/get_service_details/' + id, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestServiceDetails(response.data))
        } else {
          dispatch(requestServiceDetails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestServiceDetails(response) {
  return {
    type: SERVICE_DETAILS,
    service_details: response
  }
}

//===============================

export function getServicePricelist(id) {

  return (dispatch) => {
    return dispatch(getServicePrice(id))
  }
}

function getServicePrice(id) {

  return dispatch => {
    return Axios.get('api/getServicePricelist/' + id, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestServicePrice(response.data))
        } else {
          dispatch(requestServicePrice([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestServicePrice(response) {
  return {
    type: SERVICE_PRICE,
    service_price_list: response
  }
}


//=============================

export function getSellerSequence(post) {

  return (dispatch) => {
    return dispatch(getSellerSequencing(post))
  }
}

function getSellerSequencing(post) {
  return dispatch => {
    return Axios.post('api/get_seller_sequencing', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSellerSequencing(response.data))
        } else {
          dispatch(requestSellerSequencing([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSellerSequencing(response) {

  return {
    type: SELLER_SEQUENCE,
    seller_sequence: response,
  }
}


//====================================

export function updateSellerSequence(post, getPost) {

  return (dispatch) => {
    return dispatch(updateSellerSequencing(post, getPost))
  }
}

function updateSellerSequencing(post,getPost) {
  // let data = post.seller_list.map(x=>{
  //   return x;
  // })
  return dispatch => {
    return Axios.post('api/update_seller_sequencing', post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerSequencing(getPost))
        // if (response.data !== "") {               
        //    // dispatch(requestSellerSequencing(response.data))
        // } else {
        //     //dispatch(requestSellerSequencing([]))
        // }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

export function AddSellerSequence(post) {

  return (dispatch) => {
    return dispatch(AddSellerSequencing(post))
  }
}

function AddSellerSequencing(post) {

  return dispatch => {
    return Axios.post('api/add_seller_sequencing', post, { baseURL: baseurl })
      .then(response => {
        // if (response.data !== "") {               
        //    // dispatch(requestSellerSequencing(response.data))
        // } else {
        //     //dispatch(requestSellerSequencing([]))
        // }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

// function requestSellerSequencing(response) {

//   return {
//     type: SELLER_SEQUENCE,
//     seller_sequence: response,
//   }
// }

export function removeSellerFromSequence(id) {

  return dispatch => {
    return Axios.delete('api/remove_seller_sequencing/' + id, { baseURL: baseurl })
      .then(response => {
        // if (response.data !== "") {               
        //    // dispatch(requestSellerSequencing(response.data))
        // } else {
        //     //dispatch(requestSellerSequencing([]))
        // }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

///////////////////////////////////////////seller///////////////////////////////////////////////


///////////////////////////////////////////Buyer///////////////////////////////////////////////

export function getBuyersDetails() {

  return (dispatch) => {
    return dispatch(getBuyerDetails())
  }
}

function getBuyerDetails() {

  return dispatch => {
    return Axios.get('api/get_buyer_details', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestAllByuersDetails(response.data))
        } else {
          dispatch(requestAllByuersDetails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAllByuersDetails(response) {
  return {
    type: BUYER_DETAILS,
    buyer_details: response,
  }
}

//======================================

//============= get buyer data with pagination =================


export function getBuyersData(post) {

  return (dispatch) => {
    return dispatch(getBuyers(post))
  }
}

function getBuyers(post) {
  return dispatch => {
      return Axios.post('api/get_buyer_limit_details', post, { baseURL: baseurl })
          .then(response => {            
              if (response.data !== "") {
                  dispatch(requestgetBuyers(response.data))
              } else {
                  dispatch(requestgetBuyers([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    // dispatch(push('/'));
                    handleUnAuthorised();
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestgetBuyers(response) {

  return {
    type: BUYER_LIMIT_DETAILS,
    buyer_limit_details: response,
  }
}


//============= end buyer data with pagiation =================

export function getCaptureEmails() {

  return (dispatch) => {
    return dispatch(getCaptureEmail())
  }
}

function getCaptureEmail() {

  return dispatch => {
    return Axios.get('api/get_captured_emails', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestCaptureEmails(response.data))
        } else {
          dispatch(requestCaptureEmails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestCaptureEmails(response) {
  return {
    type: CAPTURE_EMAILS,
    capture_emails: response,
  }
}

//=================================

//===============GET FILTER LOGS=======================

export function getFilterLogs() {

  return (dispatch) => {
    return dispatch(getFilterLog())
  }
}

function getFilterLog() {

  return dispatch => {
    return Axios.get('api/get_filter_logs', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          // console.log("1) RESPONSE:---", response);
          dispatch(requestFilterLogs(response.data))
        } else {
          dispatch(requestFilterLogs([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestFilterLogs(response) {
  return {
    type: FILTER_LOGS,
    filter_logs: response,
  }
}

//=================================


//===============GET QUOTE CALC LOGS=======================

export function getQuoteCalcLogs() {

  return (dispatch) => {
    return dispatch(getQuoteLog())
  }
}

function getQuoteLog() {

  return dispatch => {
    return Axios.get('api/get_quote_calc_logs', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          // console.log("1) RESPONSE:---", response);
          dispatch(requestQuoteLogs(response.data))
        } else {
          dispatch(requestQuoteLogs([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestQuoteLogs(response) {
  return {
    type: QUOTE_CALC_LOGS,
    quote_calc_logs: response,
  }
}

//=================================

// ============== get city list ==========

export function getCityList() {
  return (dispatch) => {
    return dispatch(getCityListData())
  }
}

function getCityListData() {

  return dispatch => {
    return Axios.get('api/get_all_state', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestgetCityList(response.data))
        } else {
          dispatch(requestgetCityList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetCityList(response) {
  return {
    type: CITY_CONTENT,
    city_list: response,
  }
}

// ============ end city list ============

export function getBuyersUpdateDetails(EditData) {

  return (dispatch) => {
    return dispatch(getBuyerUpdateDetails(EditData))
  }
}

function getBuyerUpdateDetails(EditData) {
  return dispatch => {
    return Axios.put('api/update_profile')
      .then(response => {
        if (response.data !== "") {
          // dispatch(requestAllByuersDetails(response.data))
        }
        else {
          // dispatch(requestAllByuersDetails([]))
        }
      })
      .catch(error => {
        //throw(error);
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            //browserHistory.push('/');
            //  window.location.reload(); 
          }
          else {
            //console.log(error);
          }
        }
      });
  }
}
///////////////////////////////////////////Buyer///////////////////////////////////////////////


///////////////////////////////////////////General///////////////////////////////////////////////

export function GetAllCountries() {

  return (dispatch) => {
    return dispatch(GetAllCountry())
  }
}

function GetAllCountry() {
  return dispatch => {
    return Axios.get('api/get_all_country', { baseURL: baseurl })
      .then(
        response => {
          if (response.data !== "") {
            dispatch(requestGetAllCountry(response.data))
          } else {
            dispatch(requestGetAllCountry([]))
          }
        }
      ).catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      })
  }
}
function requestGetAllCountry(response) {
  return {
    type: GET_ALLCOUNTRY,
    get_allcountry: response,
  }
}

//======================================

export function GetStatesByCountry(id) {
  return (dispatch) => {
    return dispatch(GetStateByCountry(id))
  }
}

function GetStateByCountry(id) {
  return dispatch => {
    return Axios.get('api/get_state_by_country/' + id, { baseURL: baseurl })
      .then(
        response => {
          if (response.data !== "") {
            dispatch(requestGetStateByCountry(response.data))
          } else {
            dispatch(requestGetStateByCountry([]))
          }
        }
      ).catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      })
  }
}

function requestGetStateByCountry(response) {
  return {
    type: GET_STATE_BY_COUNTRY,
    get_state_by_country: response,
  }
}

//=========================================



export function GetAllCategories() {
  return (dispatch) => {
    return dispatch(GetAllCategory())
  }
}

function GetAllCategory() {
  return dispatch => {
    return Axios.get('api/get_all_categories', { baseURL: baseurl })
      .then(
        response => {
          if (response.data !== "") {
            dispatch(requestGetAllCategory(response.data))
          } else {
            dispatch(requestGetAllCategory([]))
          }
        }
      ).catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      })
  }
}

function requestGetAllCategory(response) {
  return {
    type: GET_All_CATEGORY,
    get_all_category: response,
  }
}
///////////////////////////////////////////General///////////////////////////////////////////////




///////////////////////////////////////////Dashboard///////////////////////////////////////////////

export function getDashboardDetails(post) {
  return (dispatch) => {
    return dispatch(getDashboard(post))
  }
}

function getDashboard(post) {

  return dispatch => {
    return Axios.post('api/dashboard_details', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestDashboardDetails(response.data))
        } else {
          dispatch(requestDashboardDetails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestDashboardDetails(response) {
  return {
    type: DASHBOARD_DETAILS,
    dashboard_details: response
  }
}


///////////////////////////////////////////Dashboard///////////////////////////////////////////////


///////////////////////////////////////////Login///////////////////////////////////////////////

export function userLogin(post) {
  return (dispatch) => {
    return dispatch(login(post))
  }
}
function login(post) {
  return dispatch => {
    return Axios.post('api/admin_login', post, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestLogin(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestLogin([]))
          // console.log(error);
        }
      })
  }
}

function requestLogin(response) {
  return {
    type: RECEIVE_LOGIN,
    login_user_data: response
  }
}

//===========================

// =============================== session ========================

export function getSessionAdmin() {
  return (dispatch) => {
    return dispatch(getSessionAdminData())
  }
}
function getSessionAdminData() {
  return dispatch => {
    return Axios.get('api/get_session_details', { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestAdminSession(response.data))
        }
        else {
          dispatch(requestAdminSession([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAdminSession(response) {
  return {
    type: RECEIVE_LOGIN,
    login_user_data: response
  }
}

// ============================= session end ========================

// =========================== get quote detail using order id ===========

export function getQuoteByOrder(id) {
  return (dispatch) => {
    return dispatch(getQuoteByOrderData(id))
  }
}
function getQuoteByOrderData(id) {
  return dispatch => {
    return Axios.get('api/get_quote_by_order/' + id, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestgetQuoteByOrder(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestgetQuoteByOrder([]))
          // console.log(error);
        }
      })
  }
}

function requestgetQuoteByOrder(response) {
  return {
    type: QUOTE_USING_ORDER,
    quote_using_order: response
  }
}

// ========================= end quote detail using order id =============

// ======================= get invoice details ======================


export function getInvoiceFromDashboard(id) {
  return (dispatch) => {
    return dispatch(getInvoiceFromDashboardData(id))
  }
}
function getInvoiceFromDashboardData(id) {
  return dispatch => {
    return Axios.get('api/get_invoice_from_dshboard_order/' + id, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestInvoiceFromDashboard(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestInvoiceFromDashboard([]))
          // console.log(error);
        }
      })
  }
}

function requestInvoiceFromDashboard(response) {
  return {
    type: INVOICE_FROM_DASHBOARD,
    invoice_from_dashboard: response
  }
}

// ============= end invoice details =========================

//----- get order by seller details action -----

export function getOrderBySellerInfo(id) {
  return (dispatch) => {
    return dispatch(getOrderBySellerInfoData(id))
  }
}
function getOrderBySellerInfoData(id) {
  return dispatch => {
    return Axios.get('api/get_order_details/' + id, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestOrderBySellerInfo(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestOrderBySellerInfo([]))
          // console.log(error);
        }
      })
  }
}

function requestOrderBySellerInfo(response) {
  return {
    type: GET_ORDER_BY_DETAILS,
    get_order_by_details: response
  }
}

//----- end get order by seller details action -----

//----- save/ insert invoice data action -----

export function SaveInvoice(post) {

  return (dispatch) => {
    return dispatch(SaveInvoiceData(post))
  }
}

function SaveInvoiceData(post) {
  return dispatch => {
    return Axios.post('api/insert_invoice_data', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSaveInvoice(response.data))
        } else {
          dispatch(requestSaveInvoice([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSaveInvoice(response) {

  return {
    type: INSERT_INVOICE_DATA,
    insert_invoice_data: response,
  }
}

//----- end save/ insert invoice data action -----

//------- multiple invoice data insert ----------

export function SaveMultipleInvoice(post) {

  return (dispatch) => {
    return dispatch(SaveMultipleInvoiceData(post))
  }
}

function SaveMultipleInvoiceData(post) {
  return dispatch => {
    return Axios.post('api/insert_multiple_invoice_data', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSaveMultipleInvoice(response.data))
        } else {
          dispatch(requestSaveMultipleInvoice([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSaveMultipleInvoice(response) {

  return {
    type: INSERT_MULTIPLE_INVOICE_DATA,
    insert_multiple_invoice_data: response,
  }
}

//------- end multiple invoice data insert ----------

// ----- generate pdf of invoice ------ //

function UploadInvoicePDFData(post) {
  var baseurl = config.api_base_url;
  let random = Number(Math.random() * 10000)
  return dispatch => {
    return Axios.post("/api/get_invoice_pdf", post, { baseURL: baseurl })
      .then(response => {

        if (response) {
          dispatch(requestUploadInvoicePDFData({ link: response.data, random }));
        } else {
          dispatch(requestUploadInvoicePDFData({ link: null, random }));
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            // console.log(error)
            // window.location.reload();
            handleUnAuthorised();
          } else {
            console.log(error)
          }
        }
      });
  };
}

function requestUploadInvoicePDFData(response) {
  return {
    type: UPLOAD_INVOICE_S3,
    upload_ivoice_s3: response
  };
}

export function UploadInvoicePDF(post) {
  return dispatch => {
    return dispatch(UploadInvoicePDFData(post));
  };
}


// ----- end generate pdf of invoice ------ //


export function userLogout() {
  return (dispatch) => {
    return dispatch(logout())
  }
}

function logout() {
  return dispatch => {
    return Axios.get('api/logout', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestLogout(response.data))
        }
        else {
          dispatch(requestLogout([]))
        }
      })
      .catch(error => {
        //throw(error);
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            //browserHistory.push('/');
            //  window.location.reload(); 
          }
          else {
            //console.log(error);
          }
        }
      });
  }
}

function requestLogout(response) {
  return {
    type: LOGOUT,
    logout_data: response
  }
}

///////////////////////////////////////////Login///////////////////////////////////////////////


/////////////////////////// update seller status  ////////////////////////////

export function updateSellerStatus(post) {

  return (dispatch) => {
    return dispatch(updateSellerStatusData(post))
  }
}

function updateSellerStatusData(post) {
  return dispatch => {
    return Axios.post('api/update_seller_status', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestUpdateSellerStatus(response.data))
        } else {
          dispatch(requestUpdateSellerStatus([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateSellerStatus(response) {

  return {
    type: UPDATE_SELLER_STATUS,
    update_seller_status: response,
  }
}

//----------------------

//================ seller status visible update =======================

export function updateSellerVisible(post) {

  return (dispatch) => {
    return dispatch(updateSellerVisibleData(post))
  }
}

function updateSellerVisibleData(post) {
  return dispatch => {
    return Axios.post('api/update_seller_visible_status', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestupdateSellerVisibleData(response.data))
        } else {
          dispatch(requestupdateSellerVisibleData([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href='/';
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateSellerVisibleData(response) {

  return {
    type: UPDATE_SELLER_VISIBLE,
    update_seller_visible: response,
  }
}

//---------------------- end seller profile visible update --------------------//


//================ Quote calc visible status =======================

export function updateQuoteCalcVisible(post) {

  return (dispatch) => {
    return dispatch(updateQuoteCalcVisibleData(post))
  }
}

function updateQuoteCalcVisibleData(post) {
  return dispatch => {
    return Axios.post('api/update_quote_calc_visible_status', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestupdateQuoteVisibleData(response.data))
        } else {
          dispatch(requestupdateQuoteVisibleData([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href='/';
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateQuoteVisibleData(response) {
  return {
    type: UPDATE_QUOTE_CALC_VISIBLE,
    update_quote_calc_visible: response,
  }
}

//---------------------- end quote calc visible status --------------------//

export function getAllOrderList(post) {
  return (dispatch) => {
    return dispatch(GetAllOrders(post))
  }
}

function GetAllOrders(post) {
  return dispatch => {
    return Axios.post('api/get_all_orders', post,{ baseURL: baseurl })
      .then(
        response => {
          if (response.data !== "") {
            dispatch(requestGetAllOrders(response.data))
          } else {
            dispatch(requestGetAllOrders([]))
          }
        }
      ).catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      })
  }
}

function requestGetAllOrders(response) {
  return {
    type: ORDER_LIST,
    order_list: response,
  }
}

//----------------------

export function getOrderStatusList() {
  return (dispatch) => {
    return dispatch(GetOrderStatus())
  }
}

function GetOrderStatus() {
  return dispatch => {
    return Axios.get('api/get_order_status_list', { baseURL: baseurl })
      .then(
        response => {
          if (response.data !== "") {
            dispatch(requestGetOrderStatus(response.data))
          } else {
            dispatch(requestGetOrderStatus([]))
          }
        }
      ).catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      })
  }
}

function requestGetOrderStatus(response) {
  return {
    type: ORDER_STATUS_LIST,
    order_status_list: response,
  }
}

// -----------------------


export function updateOrderStatus(order_id, status) {
  return (dispatch) => {
    return dispatch(UpdateSingleOrderStatus(order_id, status))
  }
}

function UpdateSingleOrderStatus(order_id, status) {
  return dispatch => {
    return Axios.get('api/update_order_status/' + order_id + "/" + status, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateSingleOrderStatus(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateSingleOrderStatus(response) {
  return {
    type: UPDATE_ORDER_STATUS,
    update_order_status: response
  }
}

export function updateBuyerDetails(post) {
  return (dispatch) => {
    return Axios.post('api/update_buyer_details', post, { baseURL: baseurl })
      .then((response) => {
        dispatch({
          type: UPDATE_BUYER_DETAILS,
          update_buyer_details: response
        })
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
//=============== insert Artist payment ==============

export function insertArtistPayment(post) {

  return (dispatch) => {
    return dispatch(insertArtistPaymentData(post))
  }
}

function insertArtistPaymentData(post) {
  return dispatch => {
    return Axios.post('api/insert_artist_payment', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestinsertArtistPayment(response.data))
        } else {
          dispatch(requestinsertArtistPayment([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestinsertArtistPayment(response) {

  return {
    type: INSERT_ARTIST_PAYMENT,
    insert_artist_payment: response,
  }
}

//============== end artist payment =================

// ============== get sellers with same user ==============


export function getSellersWithBuyerEmail(email, order_id) {
  return (dispatch) => {
    return dispatch(getSellersWithBuyerEmailData(email, order_id))
  }
}

function getSellersWithBuyerEmailData(email, order_id) {
  return dispatch => {
    return Axios.get('api/get_sellers_with_one_buyer/' + email + '/' + order_id, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestgetSellersWithBuyerEmail(response.data))
        } else {
          dispatch(requestgetSellersWithBuyerEmail([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetSellersWithBuyerEmail(response) {
  return {
    type: SELLER_USING_ONE_BUYER,
    seller_using_one_buyer: response,
  }
}

// ============ end of seller with same user ==============


// ================== update artist payment =================

export function updateArtistPayment(post) {
  return (dispatch) => {
    return dispatch(updateArtistPaymentData(post))
  }
}

function updateArtistPaymentData(post) {
  return dispatch => {
    return Axios.post('api/update_artist_payment', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateArtistPaymentData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateArtistPaymentData(response) {
  return {
    type: UPDATE_ARTIST_PAYMENT,
    update_artist_payment: response
  }
}


// =================== end of aartist payment =============

// ================== update artist payment new =================

export function updateSellerPayment(post) {
  return (dispatch) => {
    return dispatch(updateSellerPaymentData(post))
  }
}

function updateSellerPaymentData(post) {
  return dispatch => {
    return Axios.post('api/update_seller_payment', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateSellerPaymentData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateSellerPaymentData(response) {
  return {
    type: UPDATE_SELLER_PAYMENT,
    update_seller_payment: response
  }
}


// =================== end of artist payment new =============

//=========== delete artist payment ============

export function deleteArtistPayment(post) {
  return (dispatch) => {
    return dispatch(deleteArtistPaymentData(post))
  }
}

function deleteArtistPaymentData(post) {
  return dispatch => {
    return Axios.post('api/delete_artist_payment', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestdeleteArtistPaymentData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestdeleteArtistPaymentData(response) {
  return {
    type: DELETE_ARTIST_PAYMENT,
    delete_artist_payment: response
  }
}
//====== end delete ===============

// -------------------- file upload ---------------------------//

function uploadFilesS3(post) {
  let post_data = post
  console.log(post_data, "---------- check post data -----------")
  return dispatch => {
    return Axios.post("api/upload_to_s3", post_data, { baseURL: baseurl })
      .then(response => {
        if (response) {
          dispatch(requestuploadFilesS3(response.data));
        } else {
          dispatch(requestuploadFilesS3([]));
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            console.log(error)
            // window.location.reload();
          } else {
            console.log(error)
          }
        }
      });
  };
}

function requestuploadFilesS3(response) {
  return {
    type: UPLOAD_FILE_S3UPLOAD,
    upload_file_s3upload: response
  };
}

export function uploadFilesToS3(post) {
  return dispatch => {
    return dispatch(uploadFilesS3(post));
  };
}

// -------------------- file upload ---------------------------//

//=============== insert Artist payment ==============

export function updateArtistReceipt(post) {
  return (dispatch) => {
    return dispatch(updateArtistReceiptData(post))
  }
}

function updateArtistReceiptData(post) {
  return dispatch => {
    return Axios.post('api/update_artist_receipt', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateArtistReceiptData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateArtistReceiptData(response) {
  return {
    type: UPDATE_ARTIST_RECEIPT,
    update_artist_receipt: response
  }
}

//============== end artist payment =================

// ======= update search page content ==============

export function updateSearchPageContent(post) {
  return (dispatch) => {
    return dispatch(updateSearchPageContentData(post))
  }
}

function updateSearchPageContentData(post) {
  return dispatch => {
    return Axios.post('api/update_search_page_content', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestupdateSearchPageContent(response.data))
        } else {
          dispatch(requestupdateSearchPageContent([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateSearchPageContent(response) {
  return {
    type: UPDATE_SEARCH_PAGE_CONTENT,
    update_search_page_content: response
  }
}

//============== end search page content =================

//=============== Update Lost data ==============

export function updateLostStatus(post) {
  return (dispatch) => {
    return dispatch(updateLostStatusData(post))
  }
}

function updateLostStatusData(post) {

  return dispatch => {
    return Axios.post('api/update_lost_status', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateLostStatusData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateLostStatusData(response) {
  return {
    type: UPDATE_LOST_STATUS,
    update_lost_status: response
  }
}

//============== end uodate lost data =================

//================ get tags  =======================


export function getTagsDetails() {

  return (dispatch) => {
    return dispatch(getTags())
  }
}

function getTags() {
  return dispatch => {
    return Axios.get('api/get_tags', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestTagsDetails(response.data))
        } else {
          dispatch(requestTagsDetails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestTagsDetails(response) {

  return {
    type: TAG_DEATILS,
    tag_details: response,
  }
}

// ================ end get tags ===================

// =========== updateTagDetails ===================

export function updateTagDetails(post) {
  return (dispatch) => {
    return dispatch(updateTagDetailsData(post))
  }
}

export function updateTagDetailsData(post) {
  return (dispatch) => {
    return Axios.post('api/update_tag_details', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateTagDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateTagDetails(response) {
  return {
    type: UPDATE_TAG,
    update_tag: response
  }
}


// ============= end updateTagDetails ===========

//=============== delete tag ==================


export function deleteTag(post) {
  return (dispatch) => {
    return dispatch(deleteTagData(post))
  }
}

function deleteTagData(post) {
  return dispatch => {
    return Axios.post('api/delete_tag', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestdeleteTagData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestdeleteTagData(response) {
  return {
    type: DELETE_TAG,
    delete_tag: response
  }
}

// ============= end delete tag ================

// ============ add tag =======================


export function addTag(post) {
  return (dispatch) => {
    return dispatch(addTagData(post))
  }
}

function addTagData(post) {
  return dispatch => {
    return Axios.post('api/add_tag', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestaddTagData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestaddTagData(response) {
  return {
    type: ADD_TAG,
    add_tag: response
  }
}


// ============ end add tag =======================


export function updateOrderCommission(order_id, post) {
  return dispatch => {
    return Axios.put('api/update_order_commission/' + order_id, post, { baseURL: baseurl })
      .then(response => {
        dispatch({
          type: UPDATE_ORDER_COMMISSION,
          update_order_commission: response.data
        })
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            dispatch({
              type: UPDATE_ORDER_COMMISSION,
              update_order_commission: []
            })
            console.log(error);
          }
        }
      });
  }
}

//=============== insert buyer payment ==============

export function insertBuyerPayment(post) {
  return (dispatch) => {
    return dispatch(insertBuyerPaymentData(post))
  }
}

function insertBuyerPaymentData(post) {
  return dispatch => {
    return Axios.post('api/add_client_payment', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestinsertBuyerPayment(response.data))
        } else {
          dispatch(requestinsertBuyerPayment([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestinsertBuyerPayment(response) {

  return {
    type: INSERT_BUYER_PAYMENT,
    insert_buyer_payment: response,
  }
}

//============== end buyer payment =================

// ================== update buyer payment =================

export function updateClientPayment(post) {
  return (dispatch) => {
    return dispatch(updateClientPaymentData(post))
  }
}

function updateClientPaymentData(post) {
  return dispatch => {
    return Axios.put('api/update_client_payment', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateClientPayment(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}


function requestupdateClientPayment(response) {
  return {
    type: UPDATE_BUYER_PAYMENT,
    update_buyer_payment: response
  }
}


// =================== end of buyer payment =============

//=========== delete buyer payment ============

export function deleteBuyerPayment(post) {
  return (dispatch) => {
    return dispatch(deleteBuyerPaymentData(post))
  }
}

function deleteBuyerPaymentData(post) {
  return dispatch => {
    return Axios.post('api/delete_client_payment', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestdeleteBuyerPayment(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestdeleteBuyerPayment(response) {
  return {
    type: DELETE_BUYER_PAYMENT,
    delete_buyer_payment: response
  }
}
//====== end buyer payment delete ===============


// ============ update artist email =======================


export function updateArtistEmail(post) {
  return (dispatch) => {
    return dispatch(updateArtistEmailData(post))
  }
}

function updateArtistEmailData(post) {
  return dispatch => {
    return Axios.post('api/edit_artist_email', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateArtistEmail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateArtistEmail(response) {
  return {
    type: EDIT_ARTIST_EMAIL,
    edit_artist_email: response
  }
}


// ============ update artisr email end =======================

// ================== update artist payment note=================

export function updateArtistPaymentNote(post) {
  return (dispatch) => {
    return dispatch(updateArtistPaymentNoteData(post))
  }
}

function updateArtistPaymentNoteData(post) {
  return dispatch => {
    return Axios.post('api/insert_selller_payment_note', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateArtistPaymentNote(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateArtistPaymentNote(response) {
  return {
    type: UPDATE_ARTIST_NOTE,
    update_artist_note: response
  }
}


// =================== end of aartist payment note=============


//=========== hard delete seller ============

export function hardDelSeller(post) {
  return (dispatch) => {
    return dispatch(hardDelSellerData(post))
  }
}

function hardDelSellerData(post) {
  return dispatch => {
    return Axios.post('api/hard_delete_seller', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requesthardDelSeller(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requesthardDelSeller(response) {
  return {
    type: HARD_DEL_SELLER,
    hard_del_seller: response
  }
}
//====== end hard delete seller ===============

// ================== update job status =================

export function updateJobStatus(post) {
  return (dispatch) => {
    return dispatch(updateJobStatusData(post))
  }
}

function updateJobStatusData(post) {
  return dispatch => {
    return Axios.put('api/update_active_status', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateupdateJobStatus(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateupdateJobStatus(response) {
  return {
    type: UPDATE_ACTIVE_STATUS,
    update_active_status: response
  }
}


// =================== end update job status =============

//============================//==================== get jobs list =================== 

export function getJobsList(post) {
  return (dispatch) => {
    return dispatch(getJobsListData(post))
  }
}

function getJobsListData(post) {
  return dispatch => {
    return Axios.post('api/get_all_jobs_list', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestgetJobsList(response.data))
        } else {
          dispatch(requestgetJobsList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetJobsList(response) {
  return {
    type: JOBS_LIST,
    jobs_list: response,
  }
}

//============================ end jobs list =======================//

// ========================== insert order notes ====================//


export function insertOrderNotes(post) {
  return (dispatch) => {
    return dispatch(insertOrderNotesData(post))
  }
}

function insertOrderNotesData(post) {
  return dispatch => {
    return Axios.post('api/insert_order_notes', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestinsertOrderNotes(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestinsertOrderNotes(response) {
  return {
    type: INSERT_ORDER_NOTES,
    insert_order_notes: response
  }
}

// ========================== end insert order notes ====================//


//==================== get review list =================== 

export function getReviewList() {

  return (dispatch) => {
    return dispatch(getSellerReviewList())
  }
}

function getSellerReviewList() {

  return dispatch => {
    return Axios.get('api/get_reviews_list/', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSellerReview(response.data))
        } else {
          dispatch(requestSellerReview([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSellerReview(response) {
  return {
    type: REVIEW_LIST,
    review_list: response
  }
}

//============================ end get review list =======================//


// ================== update post job  =================

export function updatePostJobDetails(post, id) {
  return (dispatch) => {
    return dispatch(updateJobData(post, id))
  }
}

function updateJobData(post, id) {
  return dispatch => {
    return Axios.put('api/update_job/' + id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateJob(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateJob(response) {
  return {
    type: UPDATE_JOB_DETAILS,
    update_job_details: response
  }
}


// =================== end update post job  =============


// ============== get active city list ==========

export function getActiveCityList() {
  return (dispatch) => {
    return dispatch(getActiveCityListData())
  }
}

function getActiveCityListData() {

  return dispatch => {
    return Axios.get('api/get_all_active_state', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestGetActiveCityList(response.data))
        } else {
          dispatch(requestGetActiveCityList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetActiveCityList(response) {
  return {
    type: CITY_LIST_ACTIVE,
    city_list_active: response,
  }
}

// ============ end active city list ============


// ============== get all seller list with progress 100% ==========

export function getSellerListFullProgress(post) {
  return (dispatch) => {
    return dispatch(getSellerListFullProgressData(post))
  }
}

function getSellerListFullProgressData(post) {
  return dispatch => {
    return Axios.post('api/v1/get_sellers_to_live', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestGetProgressSellerList(response.data))
        } else {
          dispatch(requestGetProgressSellerList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetProgressSellerList(response) {
  return {
    type: SELLER_LIST_FULL_PROGRESS,
    seller_list_full_progress: response,
  }
}

// ============ end get all seller list with progress 100% ============


// ========================== insert search page dynamic content ====================//


export function insertSearchPageContent(post) {
  return (dispatch) => {
    return dispatch(insertDynamicData(post))
  }
}

function insertDynamicData(post) {
  return dispatch => {
    return Axios.post('api/create_content', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestInsertDynamicContent(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestInsertDynamicContent(response) {
  return {
    type: INSERT_SEARCH_PAGE_CONTENT,
    insert_search_page_content: response
  }
}

// ========================== end insert search page dynamic content ====================//


// ========================== get search page dynamic content ====================//


export function getSearchPageContent(post) {
  return (dispatch) => {
    return dispatch(getDynamicData(post))
  }
}

function getDynamicData(post) {
  return dispatch => {
    return Axios.post('api/get_content_list', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetDynamicContent(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetDynamicContent(response) {
  return {
    type: SEARCH_PAGE_CONTENT,
    search_page_content: response
  }
}

// ========================== end get search page dynamic content ====================//


// ================== update search page dynamic content =================

export function updateSearchPageDynamic(post, content_id) {
  return (dispatch) => {
    return dispatch(updateSearchPageDynamicData(post, content_id))
  }
}

function updateSearchPageDynamicData(post, content_id) {
  return dispatch => {
    return Axios.put('api/update_content/' + content_id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateSearchPageData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateSearchPageData(response) {
  return {
    type: UPDATE_SEARCH_PAGE_DYNAMIC,
    update_search_page_dynamic: response
  }
}


// =================== end search page dynamic content =============


// ========================== get FQA dynamic content ====================//


export function getFaqContent(post) {
  return (dispatch) => {
    return dispatch(getDynamicFaqData(post))
  }
}

function getDynamicFaqData(post) {
  return dispatch => {
    return Axios.post('api/get_faq_list', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetDynamicFaqContent(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetDynamicFaqContent(response) {
  return {
    type: FAQ_CONTENT,
    faq_content: response
  }
}

// ========================== end get FAQ dynamic content ====================//


// ========================== insert country ====================//


export function createCountry(post) {
  return (dispatch) => {
    return dispatch(insertCountryData(post))
  }
}

function insertCountryData(post) {
  return dispatch => {
    return Axios.post('api/create_country', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestInsertCountry(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestInsertCountry(response) {
  return {
    type: CREATE_COUNTRY,
    create_country: response
  }
}

// ========================== end insert country ====================//


// ========================== insert state ====================//


export function createState(post) {
  return (dispatch) => {
    return dispatch(insertStateData(post))
  }
}

function insertStateData(post) {
  return dispatch => {
    return Axios.post('api/create_state', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestInsertState(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestInsertState(response) {
  return {
    type: CREATE_STATE,
    create_state: response
  }
}

// ========================== end insert state ====================//


// ================== update country  =================

export function updateCountryDetails(post, id) {
  return (dispatch) => {
    return dispatch(updateCountryData(post, id))
  }
}

function updateCountryData(post, id) {
  return dispatch => {
    return Axios.put('api/update_country/' + id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateCountry(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateCountry(response) {
  return {
    type: UPDATE_COUNTRY_DETAILS,
    update_country_details: response
  }
}

// =================== end update country  =============


// ================== update state  =================

export function updateStateDetails(post, id) {
  return (dispatch) => {
    return dispatch(updateStateData(post, id))
  }
}

function updateStateData(post, id) {
  return dispatch => {
    return Axios.put('api/update_state/' + id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateState(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateState(response) {
  return {
    type: UPDATE_STATE_DETAILS,
    update_state_details: response
  }
}

// =================== end update state  =============


// ========================== insert admin bank details ====================//


export function createBankDetails(post) {
  return (dispatch) => {
    return dispatch(insertBankData(post))
  }
}

function insertBankData(post) {
  return dispatch => {
    return Axios.post('api/add_bank_details', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestInsertBank(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestInsertBank(response) {
  return {
    type: CREATE_BANK_DETAILS,
    create_bank_details: response
  }
}

// ========================== end admin bank details ====================//


// ================== update admin bank details  =================

export function updateBankDetails(post, id) {
  return (dispatch) => {
    return dispatch(updateBankData(post, id))
  }
}

function updateBankData(post, id) {
  return dispatch => {
    return Axios.put('api/update_bank_details/' + id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateBank(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateBank(response) {
  return {
    type: UPDATE_BANK_DETAILS,
    update_bank_details: response
  }
}

// =================== end update admin bank details  =============


// ================== get admin bank details  =================

export function getBankDetails() {
  return (dispatch) => {
    return dispatch(getAllBankDetails())
  }
}

function getAllBankDetails() {
  return dispatch => {
    return Axios.get('api/get_all_bank_details', { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetAllBankDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}
function requestGetAllBankDetails(response) {
  return {
    type: GET_ALL_BANK_DETAILS,
    get_all_bank_details: response,
  }
}

// =================== end get admin bank details  =============

// =============== update multiple order status ================


export function updateMultipleOrderStatus(post) {
  return (dispatch) => {
    return dispatch(UpdateMultipleOrderStatusData(post))
  }
}

function UpdateMultipleOrderStatusData(post) {
  return dispatch => {
    return Axios.post('api/update_multiple_order_status', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateMultipleOrderStatus(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateMultipleOrderStatus(response) {
  return {
    type: UPDATE_MULTIPLE_ORDER_STATUS,
    update_multiple_order_status: response
  }
}

// ============= end update multiple order =====================

// ================== get order details by id start =================

export function getOrderDetailsById(id) {
  return (dispatch) => {
    return dispatch(getIndividualOrderDetails(id))
  }
}

function getIndividualOrderDetails(id) {
  return dispatch => {
    return Axios.get('api/get_order_detail/' + id, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetIndividualOrder(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}


function requestGetIndividualOrder(response) {
  return {
    type: ORDER_DETAILS_BY_ID,
    order_details_by_id: response
  }
}

// =================== get order details by id end =============


// ================== get order conversation by id start =================

export function getOrderConversationById(id) {
  return (dispatch) => {
    return dispatch(getOrderConvById(id))
  }
}

function getOrderConvById(id) {
  return dispatch => {
    return Axios.get('api/get_order_conversation/' + id, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetOrderConv(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetOrderConv(response) {
  return {
    type: ORDER_CONVERSATION_BY_ID,
    order_conversation_by_id: response
  }
}

// =================== get order conversation by id end =============


// ================== get other associated order details by user_id and order_id start =================

export function getOtherOrderDetailsById(post) {
  return (dispatch) => {
    return dispatch(getOtherOrderById(post))
  }
}

function getOtherOrderById(post) {
  return dispatch => {
    return Axios.post('api/get_other_orders_detail', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetOtherOrderDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetOtherOrderDetails(response) {
  return {
    type: OTHER_ORDER_DETAILS,
    other_order_details: response
  }
}

// =================== get other associated order details by user_id and order_id end =============


// ================== post buyer notes using user_id start =================

export function addBuyerNotes(post) {
  return (dispatch) => {
    return dispatch(addBuyerNotesById(post))
  }
}

function addBuyerNotesById(post) {
  return dispatch => {
    return Axios.post('api/add_notes_to_buyer', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestPostBuyerNotes(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestPostBuyerNotes(response) {
  return {
    type: ADD_BUYER_NOTES,
    add_buyer_notes: response
  }
}

// =================== post buyer notes using user_id end =============


// ================== post email to CA from payments flow start =================

export function sendPaymentEmail(post) {
  return (dispatch) => {
    return dispatch(sendPaymentEmailAdmin(post))
  }
}

function sendPaymentEmailAdmin(post) {
  return dispatch => {
    return Axios.post('api/send_payment_confirmation_email', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestSendPaymentEmail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSendPaymentEmail(response) {
  return {
    type: SEND_PAYMENT_EMAIL,
    send_payment_email: response
  }
}

// =================== post email to CA from payments flow end =============

// =================== job details ========================


export function jobDetails(post) {
  return (dispatch) => {
    return dispatch(jobDetailsData(post))
  }
}

function jobDetailsData(post) {
  return dispatch => {
    return Axios.post('api/get_jobs_detail', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestjobDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestjobDetails(response) {
  return {
    type: JOB_DETAILS,
    job_details: response
  }
}


// ================== end job details =====================


///////////////////////////////////////////Order Conversation all list ///////////////////////////////////////////////

export function getAllOrderDetails(post) {
  return (dispatch) => {
    return dispatch(getOrderDetail(post))
  }
}

function getOrderDetail(post) {
  return dispatch => {
    return Axios.post('api/order_list_details', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestOrderDetails(response.data))
        } else {
          dispatch(requestOrderDetails([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestOrderDetails(response) {
  return {
    type: ORDER_DETAILS,
    order_list_details: response
  }
}


///////////////////////////////////////////Order Conversation all list///////////////////////////////////////////////

// ======== update the client sucess status for order ============


export function updateOrderCsStatus(post) {
  return (dispatch) => {
    return dispatch(updateOrderCsStatusData(post))
  }
}

function updateOrderCsStatusData(post) {
  return dispatch => {
    return Axios.post('api/update_cs_status_order', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateOrderCsStatus(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateOrderCsStatus(response) {
  return {
    type: UPDATE_CS_STATUS,
    update_cs_status: response
  }
}

// ======== end update the client sucess status for order ============

///////////////////////////////////////////Order Conversation data ///////////////////////////////////////////////

export function getOrderConversationWithAnalytics(post) {
  return (dispatch) => {
    return dispatch(getOrderConversationWithAnalytic(post))
  }
}

function getOrderConversationWithAnalytic(post) {

  return dispatch => {
    return Axios.get('api/get_order_conversation_with_analytics/' + post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestOrderConversationWithAnalytic(response.data))
        } else {
          dispatch(requestOrderConversationWithAnalytic([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestOrderConversationWithAnalytic(response) {
  return {
    type: ORDER_CONVERSATION_WITH_ANALYTICS,
    get_order_conversation_with_analytics: response
  }
}


///////////////////////////////////////////Order Conversation data///////////////////////////////////////////////

// ======== update client success conversation read status ============

export function updateCsConversationReadStatus(post) {
  return (dispatch) => {
    return dispatch(updateCsConversationReadStatusData(post))
  }
}

function updateCsConversationReadStatusData(post) {
  return dispatch => {
    return Axios.post('api/update_to_read' , post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateCsConversationReadStatusData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateCsConversationReadStatusData(response) {
  return {
    type: CS_CONVERSATION_READ_STATUS,
    cs_conversation_read_status: response
  }
}

// ======== end update client success conversation read status ============


// ======== update client success conversation unread status ============

export function updateCsConversationUnreadStatus(post) {
  return (dispatch) => {
    return dispatch(updateCsConversationUnreadStatusData(post))
  }
}

function updateCsConversationUnreadStatusData(post) {
  return dispatch => {
    return Axios.get('api/update_to_unread/' + post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateCsConversationUnreadStatusData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateCsConversationUnreadStatusData(response) {
  return {
    type: CS_CONVERSATION_UNREAD_STATUS,
    cs_conversation_unread_status: response
  }
}

// ======== end update client success conversation read status ============

// ======== update the client sucess status for order ============


export function updateCsFollowUp(post) {
  return (dispatch) => {
    return dispatch(updateCsFollowUpData(post))
  }
}

function updateCsFollowUpData(post) {
  return dispatch => {
    return Axios.post('api/update_cs_follow_up', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateCsFollowUp(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateCsFollowUp(response) {
  return {
    type: UPDATE_CS_FOLLOW_UP,
    update_cs_follow_up: response
  }
}

// ======== end update the client sucess status for order ============

// ================== get all flag order detials details  =================

export function getAllFlagOrderDetails() {
  return (dispatch) => {
    return dispatch(getAllFlagDetails())
  }
}

function getAllFlagDetails() {
  return dispatch => {
    return Axios.get('api/get_all_flag_order_details', { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetAllFlagDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetAllFlagDetails(response) {
  return {
    type: GET_ALL_FLAG_DETAILS,
    get_all_flag_details: response,
  }
}

// =================== end get admin bank details  =============



// =================== post insert flag order details  =============
export function addFlagOrderDetails(post) {
  return dispatch => {
    return dispatch(sendFlagOrderDetails(post));
  };
}

function sendFlagOrderDetails(post) {
  return dispatch => {
    return Axios.post('api/add_flag_order_details', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestAddFlagOrderDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAddFlagOrderDetails(response) {
  return {
    type: ADD_FLAG_ORDER_DETAILS,
    add_flag_order_details: response
  };
}

// =================== end post insert flag order details  =============


// ================== update flag order details  =================

export function updateFlagOrderDetails(post, id) {
  return (dispatch) => {
    return dispatch(sendUpdateFlagOrderDetails(post, id))
  }
}

function sendUpdateFlagOrderDetails(post, id) {
  return dispatch => {
    return Axios.put('api/update_flag_order_details/' + id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateFlagOrderDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateFlagOrderDetails(response) {
  return {
    type: UPDATE_FLAG_ORDER_DETAILS,
    update_flag_order_details: response
  }
}

// =================== end update flag order details  =============

// ======== reporting API ===========================


export function getReporting(post) {
  return (dispatch) => {
    return dispatch(getReportingData(post))
  }
}

function getReportingData(post) {
  return dispatch => {
    return Axios.post('api/reportingApi', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestgetReporting(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetReporting(response) {
  return {
    type: GET_REPORTING,
    get_reporting: response
  }
}

// ========= end of reporting API ===================

// =============== getting city using latitude and longitude ======//


export function getCityUsingLatLong(post) {
  return dispatch => {
    return dispatch(getCityUsingLatLongData(post));
  };
}

function getCityUsingLatLongData(post) {
  return dispatch => {
    return Axios.post('api/post_job_city', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestgetCityUsingLatLong(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetCityUsingLatLong(response) {
  return {
    type: GET_CITY_LAT_LONG,
    get_city_lat_lan: response
  };
}

// ====== end getting city using latitude and longitude ======//

/////////////////////////////////////////// update customer type ///////////////////////////////////////////////

export function updateCsCustomerType(post) {
  return (dispatch) => {
    return dispatch(updateCsCustomerTypeData(post))
  }
}

function updateCsCustomerTypeData(post) {

  return dispatch => {
    return Axios.post('api/update_customer_type', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestUpdateCsCustomerTypeData(response.data))
        } else {
          dispatch(requestUpdateCsCustomerTypeData([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateCsCustomerTypeData(response) {
  return {
    type: UPDATE_CS_CUSTOMER_TYPE,
    update_cs_customer_type: response
  }
}


/////////////////////////////////////////// end update customer type ///////////////////////////////////////////////

/////////////////////////////////////////// Add seller notes ///////////////////////////////////////////////

export function updateSellerNotes(post) {
  return (dispatch) => {
    return dispatch(updateSellerNotesData(post))
  }
}

function updateSellerNotesData(post) {

  return dispatch => {
    return Axios.post('api/update_seller_notes', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestUpdateSellerNotesData(response.data))
        } else {
          dispatch(requestUpdateSellerNotesData([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateSellerNotesData(response) {
  return {
    type: UPDATE_SELLER_NOTES,
    update_seller_notes: response
  }
}


/////////////////////////////////////////// end Add seller notes///////////////////////////////////////////////

//==================== get seller experience list =================== 

export function getSellerExperience() {
  return (dispatch) => {
    return dispatch(getSellerExperienceList())
  }
}

function getSellerExperienceList() {

  return dispatch => {
    return Axios.get('api/get_seller_experince_details', { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSellerExperienceList(response.data))
        } else {
          dispatch(requestSellerExperienceList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSellerExperienceList(response) {
  return {
    type: SELLER_EXPERIENCE_LIST,
    seller_experience_list: response
  }
}

//============================ end get seller experience list =======================//

// ================== get order call recording  =================

export function getOrderCallRecording(post) {
  return (dispatch) => {
    return dispatch(getOrderCallRecordingDetails(post))
  }
}

function getOrderCallRecordingDetails(post) {
  return dispatch => {
    return Axios.post('api/order_get_all_call_details', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestOrderCallRecordingDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestOrderCallRecordingDetails(response) {
  return {
    type: ORDER_CALL_RECORDING,
    order_call_recording: response,
  }
}

// =================== end order call recording  =============

// ================== get all call recording  =================

export function getAllCallRecording(post) {
  return (dispatch) => {
    return dispatch(getAllCallRecordingDetails(post))
  }
}

function getAllCallRecordingDetails(post) {
  return dispatch => {
    return Axios.post('api/get_all_call_details',post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestAllCallRecordingDetails(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAllCallRecordingDetails(response) {
  return {
    type: All_ORDER_CALL_RECORDING_LIST,
    all_order_call_recording_list: response,
  }
}

// =================== end get all call recording  =============


// ================== get buyer review and seller experience  =================

export function getSellerBuyerReviews(post) {
  return (dispatch) => {
    return dispatch(getAllSellerBuyerReviews(post))
  }
}

function getAllSellerBuyerReviews(post) {
  return dispatch => {
    return Axios.post('api/get_seller_and_buyer_reviews', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestAllSellerBuyerReviews(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAllSellerBuyerReviews(response) {
  return {
    type: SELLER_BUYER_REVIEWS,
    seller_buyer_reviews: response,
  }
}

// =================== end get buyer review and seller experience  =============

// =================== update cs follow up date ================

export function updateCsFollowUpDate(post) {
  return (dispatch) => {
    return dispatch(csFollowUpDateChange(post))
  }
}

function csFollowUpDateChange(post) {
  return dispatch => {
    return Axios.put('api/update_order_cs_follow_up_date/' + post.order_id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestCsFollowUpDateChange(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestCsFollowUpDateChange(response) {
  return {
    type: UPDATE_CS_FOLLOW_UP_DATE,
    update_cs_follow_up_date: response
  }
}

// =================== end update cs follow up date ================

// ================== post a job email trigger  =================

export function pojEmailTrigger(post) {
  return (dispatch) => {
    return dispatch(postPojEmailTrigger(post))
  }
}

function postPojEmailTrigger(post) {
  return dispatch => {
    return Axios.post('api/poj_email_trigger', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestPostPojMagicLinkEmail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestPostPojMagicLinkEmail(response) {
  return {
    type: POJ_EMAIL_TRIGGER,
    poj_email_trigger: response,
  }
}

// =================== end post a job email trigger  =============

// =================== get order based auto login link ================

export function getOrderBasedAutoLoginLink(post) {
  return (dispatch) => {
    return dispatch(orderBasedAutoLoginLink(post))
  }
}

function orderBasedAutoLoginLink(post) {
  return dispatch => {
    return Axios.post('api/order_base_auto_login_link', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestOrderBasedAutoLoginLink(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestOrderBasedAutoLoginLink(response) {
  return {
    type: ORDER_BASED_AUTO_LOGIN_LINK,
    order_based_auto_login_link: response
  }
}

// =================== end get order based auto login link ================


// =================== get not intersted order list ================

export function notInterestedOrderData(post) {
  return (dispatch) => {
    return dispatch(notInterestedOrderDataList(post))
  }
}

function notInterestedOrderDataList(post) {
  return dispatch => {
    return Axios.post('api/not_intrested_order', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestNotInterestedOrderDataList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestNotInterestedOrderDataList(response) {
  return {
    type: NOT_INTERESTED_ORDER_LIST,
    not_interested_order_list: response
  }
}

// =================== end get not intersted order list ================


// =================== update seller details common ================

export function updateSellerDetailsCommon(post, seller_id) {
  return (dispatch) => {
    return dispatch(updateSellerDetailsCommonData(post, seller_id))
  }
}

function updateSellerDetailsCommonData(post, seller_id) {
  return dispatch => {
    return Axios.put('api/update_seller_details_common/' + seller_id, post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUpdateSellerDetailsCommon(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateSellerDetailsCommon(response) {
  return {
    type: UPDATE_SELLER_DETAILS_COMMON,
    update_seller_details_common: response
  }
}

// =================== end update seller details common ================

// =================== get order flag details list ================

export function getFlagOrderDetailsList(post) {
  return (dispatch) => {
    return dispatch(flagOrderDetailsListData(post))
  }
}

function flagOrderDetailsListData(post) {
  return dispatch => {
    return Axios.post('api/flag_order_list', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestFlagOrderDetailsListData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestFlagOrderDetailsListData(response) {
  return {
    type: FLAG_ORDER_DETAILS_LIST,
    flag_order_details_list: response
  }
}

// =================== get order flag details list ================

// =================== get all orders list associated to buyer email ===========

export function getAllOrderByBuyerEmail(post) {
  return (dispatch) => {
    return dispatch(getAllOrderByBuyerEmailData(post))
  }
}

function getAllOrderByBuyerEmailData(post) {
  return dispatch => {
    return Axios.post('api/order_list_details', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestGetAllOrderByBuyerEmailData(response.data))
        } else {
          dispatch(requestGetAllOrderByBuyerEmailData([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetAllOrderByBuyerEmailData(response) {
  return {
    type: ORDER_DETAILS_BY_BUYER,
    order_list_details_by_buyer: response
  }
}


// ================== end get all orders list associated to buyer email ==========

// =================== action to marked as read multiple orders ===========

export function markAsReadMultipleOrder(post) {
  return (dispatch) => {
    return dispatch(actionMarkAsReadMultipleOrder(post))
  }
}

function actionMarkAsReadMultipleOrder(post) {
  return dispatch => {
    return Axios.post('api/update_read_multiple_order', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestActionMarkAsReadMultipleOrder(response.data))
        } else {
          dispatch(requestActionMarkAsReadMultipleOrder([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestActionMarkAsReadMultipleOrder(response) {
  return {
    type: MARKED_AS_READ_MULTIPLE_ORDER,
    mark_as_read_multiple_order: response
  }
}

// =================== end action to marked as read multiple orders ===========

//----- get single order details for conversation page ----- //

export function getSingleOrderDetails(post) {
  return (dispatch) => {
    return dispatch(getSingleOrderDetailsData(post))
  }
}
function getSingleOrderDetailsData(post) {
  return dispatch => {
    return Axios.get('api/get_single_order_details/' + post.order_id, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestGetSingleOrderDetailsData(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestGetSingleOrderDetailsData([]))
          // console.log(error);
        }
      })
  }
}

function requestGetSingleOrderDetailsData(response) {
  return {
    type: GET_SINGLE_ORDER_DETAILS,
    get_single_order_details: response
  }
}

//----- end get single order details for conversation page ----- //

// =================== Add Email template ===========

export function addEmailTemplate(post) {
  return (dispatch) => {
    return dispatch(addEmailTemplateAction(post))
  }
}

function addEmailTemplateAction(post) {
  return dispatch => {
    return Axios.post('api/insert_template', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestAddEmailTemplateAction(response.data))
        } else {
          dispatch(requestAddEmailTemplateAction([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAddEmailTemplateAction(response) {
  return {
    type: ADD_EMAIL_TEMPLATE,
    add_email_template: response
  }
}

// =================== end Add Email template ===========


//----- get email templates ----- //

export function getEmailTemplate(post) {
  return (dispatch) => {
    return dispatch(getEmailTemplatesData(post))
  }
}
function getEmailTemplatesData(post) {
  return dispatch => {
    return Axios.post('api/get_admin_template', post, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestGetEmailTemplatesData(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestGetEmailTemplatesData([]))
          // console.log(error);
        }
      })
  }
}

function requestGetEmailTemplatesData(response) {
  return {
    type: GET_EMAIL_TEMPLATE_LIST,
    get_email_template_list: response
  }
}

//----- end get email templates ----- //

//----- update email templates ----- //

export function updateEmailTemplate(post) {
  return (dispatch) => {
    return dispatch(updateEmailTemplateData(post))
  }
}
function updateEmailTemplateData(post) {
  return dispatch => {
    return Axios.put('api/update_template/' + post.id, post, { baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestUpdateEmailTemplateData(response.data))
          //window.location.href='/dashboard';
          //dispatch(push('/dashboard'));
        }
        else {
          dispatch(requestUpdateEmailTemplateData([]))
          // console.log(error);
        }
      })
  }
}

function requestUpdateEmailTemplateData(response) {
  return {
    type: UPDATE_EMAIL_TEMPLATE,
    update_email_template: response
  }
}

//----- update get email templates ----- //


// =================== Add Email template ===========

export function sendEmailTemplateViaEmail(post) {
  return (dispatch) => {
    return dispatch(sendEmailTemplateViaEmailAction(post))
  }
}

function sendEmailTemplateViaEmailAction(post) {
  return dispatch => {
    return Axios.post('api/send_admin_template_via_email', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestSendEmailTemplateViaEmailAction(response.data))
        } else {
          dispatch(requestSendEmailTemplateViaEmailAction([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSendEmailTemplateViaEmailAction(response) {
  return {
    type: SEND_EMAIL_TEMPLATE_VIA_EMAIL,
    send_email_template_via_email: response
  }
}

// =================== end Add Email template ===========

//==================== get reviews =================== 

export function getReviews(post) {

  return (dispatch) => {
    return dispatch(getAllReviews(post))
  }
}

function getAllReviews(post) {

  return dispatch => {
    return Axios.post('api/get_reviews', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestAllReviews(response.data))
        } else {
          dispatch(requestAllReviews([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAllReviews(response) {
  return {
    type: GET_REVIEWS,
    get_reviews: response
  }
}

//============================ end get reviews =======================//

//==================== convert to poj =================== 

export function converToPOJ(post) {

  return (dispatch) => {
    return dispatch(converToPOJAction(post))
  }
}

function converToPOJAction(post) {

  return dispatch => {
    return Axios.post('api/convert_order_to_poj', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestPOJAction(response.data))
        } else {
          dispatch(requestPOJAction([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestPOJAction(response) {
  return {
    type: CONVERT_TO_POJ,
    convert_to_poj: response
  }
}

//============================ end convert to poj =======================//

//==================== convert to poj =================== 

export function marketingNotes(post, id) {

  return (dispatch) => {
    return dispatch(marketingNotesAction(post, id))
  }
}

function marketingNotesAction(post, id) {

  return dispatch => {
    return Axios.put('api/marketing_notes/' + id, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestMarketingNotes(response.data))
        } else {
          dispatch(requestMarketingNotes([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestMarketingNotes(response) {
  return {
    type: UPDATE_MARKETING_NOTES,
    update_marketing_notes: response
  }
}

//============================ end convert to poj =======================//

//=============== order call update ==============

export function updateOrderCallFlag(post) {
  return (dispatch) => {
    return dispatch(handleUpdateOrderCallFlag(post))
  }
}

function handleUpdateOrderCallFlag(post) {
  return dispatch => {
    return Axios.post('api/update_order_call_flag', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestHandleUpdateOrderCallFlag(response.data))
        } else {
          dispatch(requestHandleUpdateOrderCallFlag([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestHandleUpdateOrderCallFlag(response) {
  return {
    type: UPDATE_ORDER_CALL_FLAG,
    update_order_call_flag: response,
  }
}

//============== end order call update =================

//=============== change sellerlist stripe status ==============

export function sellerStripeVisibleUpdate(post) {
  return (dispatch) => {
    return dispatch(handleSellerStripeVisible(post))
  }
}

function handleSellerStripeVisible(post) {
  return dispatch => {
    return Axios.patch(`api/stripe_visible/${post.seller_id}`, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestHandleSellerStripeVisible(response.data))
        } else {
          dispatch(requestHandleSellerStripeVisible([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestHandleSellerStripeVisible(response) {
  return {
    type: UPDATE_SELLER_STRIPE_VISIBLE,
    update_seller_stripe_visible: response,
  }
}

//============== end change sellerlist stripe status =================

//=============== email logs ==============

export function getEmailLogs(post) {
  return (dispatch) => {
    return dispatch(handleGetEmailLogs(post))
  }
}

function handleGetEmailLogs(post) {
  return dispatch => {
    return Axios.post(`api/get-all-email-logs`, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestGetEmailLogs(response.data))
        } else {
          dispatch(requestGetEmailLogs([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetEmailLogs(response) {
  return {
    type: GET_EMAIL_LOGS,
    get_email_logs: response,
  }
}

//============== end email logs =================

//=============== get send email template ==============

export function getSendEmailTemplate(post) {
  return (dispatch) => {
    return dispatch(handleGetSendEmailTemplate(post))
  }
}

function handleGetSendEmailTemplate(post) {
  return dispatch => {
    return Axios.get(`api/get-email-template-by-id/${post.template_id}`, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestGetSendEmailTemplate(response.data))
        } else {
          dispatch(requestGetSendEmailTemplate([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetSendEmailTemplate(response) {
  return {
    type: GET_SEND_EMAIL_TEMPLATE_BY_ID,
    get_send_email_template_by_id: response,
  }
}

//============== change sellerlist stripe status =================

//=============== get seller all data export ==============

export function exportSellerAllDetails(post) {
  return (dispatch) => {
    return dispatch(handleExportSellerAllDetails(post))
  }
}

function handleExportSellerAllDetails(post) {
  return dispatch => {
    return Axios.post(`api/v1/get_seller_details`, post, { baseURL: baseurl })
    // return Axios.post(`api/v2/get_seller_details`, post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {               
                dispatch(requestExportSellerAllDetails(response.data))
            } else {
                dispatch(requestExportSellerAllDetails([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestExportSellerAllDetails(response) {
  return {
    type: EXPORT_SELLER_ALL_DATA,
    export_seller_all_data: response,
  }
}

//============== end get seller all data export =================

//=============== get deleted artist data ==============

export function deletedArtistList(post) {
  return (dispatch) => {
    return dispatch(handleDeletedArtistList(post))
  }
}

function handleDeletedArtistList(post) {
  return dispatch => {
    return Axios.post(`api/deleted-seller-list`, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestDeletedArtistList(response.data))
        } else {
          dispatch(requestDeletedArtistList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestDeletedArtistList(response) {
  return {
    type: DELETED_ARTIST_LIST,
    deleted_artist_list: response,
  }
}

//============== end get deleted artist data =================

//=============== get deleted artist data ==============

export function spamOrderJobList(post) {
  return (dispatch) => {
    return dispatch(handleSpamOrderJobList(post))
  }
}

function handleSpamOrderJobList(post) {
  return dispatch => {
    return Axios.post(`api/all-spam-jobs`, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestSpamOrderJobList(response.data))
        } else {
          dispatch(requestSpamOrderJobList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSpamOrderJobList(response) {
  return {
    type: SPAM_ORDER_JOB_LIST,
    spam_order_job_list: response,
  }
}

//============== end get deleted artist data =================

//=============== update seller role  ==============

export function updateSellerRole(post) {
  return (dispatch) => {
    return dispatch(handleUpdateSellerRole(post))
  }
}

function handleUpdateSellerRole(post) {
  return dispatch => {
    return Axios.post(`api/update_seller_role`, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestUpdateSellerRole(response.data))
        } else {
          dispatch(requestUpdateSellerRole([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUpdateSellerRole(response) {
  return {
    type: UPDATE_SELLER_ROLE,
    update_seller_role: response,
  }
}

//============== end update seller role =================

//=============== send yatin meeting notification  ==============

export function yatingMeetingNotification(sellerId) {
  return (dispatch) => {
    return dispatch(handleYatingMeetingNotification(sellerId))
  }
}

function handleYatingMeetingNotification(sellerId) {
  return dispatch => {
    return Axios.post(`api/schedule-meeting/${sellerId}`,{ baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {               
                dispatch(requestYatingMeetingNotification(response.data))
            } else {
                dispatch(requestYatingMeetingNotification([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestYatingMeetingNotification(response) {
  return {
    type: YATIN_MEETING_NOTIFICATION,
    yatin_meeting_notification: response,
  }
}

//============== end yatin meeting notification =================

//==================== convert to poj v2 =================== 

export function converToPOJv2(post) {

  return (dispatch) => {
      return dispatch(converToPOJv2Action(post))
  }
}

function converToPOJv2Action(post) {
 
  return dispatch => {
      return Axios.post('api/v2/convert_order_to_poj', post ,{ baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestPOJv2Action(response.data))
              } else {
                  dispatch(requestPOJv2Action([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestPOJv2Action(response) {
  return {
      type: CONVERT_TO_POJ_V2,
      convert_to_poj_v2: response
  }
}

//============================ end convert to poj v2 =======================//
// ======================Coupon list  ======================//

export function CouponList(post) {
  return (dispatch) => {
    return dispatch(handleCouponList(post))
  }
}

function handleCouponList(post) {
  return dispatch => {
    return Axios.post(`api/get_all_coupons`, post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {     
                dispatch(requestCouponList(response.data))
            } else {
                dispatch(requestCouponList([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestCouponList(response) {
  return {
    type: COUPON_LIST,
    get_coupon: response,
  }
}

//============================ hard delete Buyer =======================//

export function hardDeleteBuyer(post) {
  return (dispatch) => {
      return dispatch(hardDeleteBuyerAction(post))
  }
}

function hardDeleteBuyerAction(post) {
  return dispatch => {
      return Axios.post('api/buyer_deactivate', post ,{ baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requesthardDeleteBuyerAction(response.data))
              } else {
                  dispatch(requesthardDeleteBuyerAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requesthardDeleteBuyerAction(response) {
  return {
      type: HARD_DELETE_BUYER,
      hard_delete_buyer: response
  }
}

//============================ end hard delete Buyer =======================//

//============================ Get tip information =======================//

export function getTipInformation(post,order_id) {
  return (dispatch) => {
      return dispatch(tipInformationAction(post,order_id))
  }
}

function tipInformationAction(post,order_id) {
  return dispatch => {
      return Axios.get('api/get_tip_information/'+order_id, { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestTipInformationAction(response.data))
              } else {
                  dispatch(requestTipInformationAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestTipInformationAction(response) {
  return {
      type: GET_TIP_INFORMATION,
      get_tip_information: response
  }
}

//============================ end Get tip information =======================//

//============================ Empty tip information =======================//

export function emptyTipInformation() {
  return (dispatch) => {
      return dispatch(emptyTipInformationAction())
  }
}

function emptyTipInformationAction() {
  return dispatch => {
    requestEmptyTipInformationAction([])
  }
}

function requestEmptyTipInformationAction(response) {
  return {
      type: GET_TIP_INFORMATION,
      get_tip_information: response
  }
}
//============================ end empty tip information =======================//

//============================ notify artist for job =======================//

export function notifyArtistForJob(post) {
  return (dispatch) => {
    return dispatch(notifyArtistForJobFunction(post))
  }
}

function notifyArtistForJobFunction(post) {
 
  return dispatch => {
      return Axios.get('api/notify_artist/'+post.id, { baseURL: baseurl })
          .then(response => {
        
              if (response.data !== "") {               
                  dispatch(requestNotifyArtistForJob(response.data))
              } else {
                  dispatch(requestNotifyArtistForJob([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestNotifyArtistForJob(response) {
  return {
    type: NOTIFY_ARTIST_FOR_JOB,
    notify_artist_for_job: response
  }
}

//============================ end notify artist for job =======================//

//============================ notify artist for job =======================//

export function deleteJob(post) {
  return (dispatch) => {
    return dispatch(deleteJobFunction(post))
  }
}

function deleteJobFunction(post) {
 
  return dispatch => {
      return Axios.delete('api/delete_job/'+post.job_id, { baseURL: baseurl })
          .then(response => {
              if (response.data !== "") {               
                  dispatch(requestDeleteJob(response.data))
              } else {
                  dispatch(requestDeleteJob([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestDeleteJob(response) {
  return {
    type: DELETE_JOB,
    delete_job: response
  }
}

//============================ end notify artist for job =======================//

// ======================Hold payment list  ======================//

export function holdPaymentList(post) {
  return (dispatch) => {
    return dispatch(handleHoldPaymentList(post))
  }
}

function handleHoldPaymentList(post) {
  return dispatch => {
    return Axios.post(`api/get_all_hold_payment`,post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestHoldPaymentList(response.data))
            } else {
                dispatch(requestHoldPaymentList([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestHoldPaymentList(response) {
  return {
    type: HOLD_PAYMENT_LIST,
    get_hold_payment_list: response,
  }
}

// ======================  end Hold payment list  ======================//

// ======================All payment list  ======================//

export function AllPaymentList(post) {
  return (dispatch) => {
    return dispatch(handleAllPaymentList(post))
  }
}

function handleAllPaymentList(post) {
  return dispatch => {
    return Axios.post(`api/get_all_payment`, post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestAllPaymentList(response.data))
        } else {
          dispatch(requestAllPaymentList([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAllPaymentList(response) {
  return {
    type: ALL_PAYMENT_LIST,
    get_all_payment_list: response,
  }
}

// ====================== end All payment list  ======================//


// ====================== get Seller subscription list ======================//

export function getSellerSubscriptionList() {
  return (dispatch) => {
    return dispatch(handleGetSellerSubscriptionList())
  }
}

function handleGetSellerSubscriptionList() {
  return dispatch => {
    return Axios.get(`api/get_subscription_role`, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestGetSellerSubscriptionList(response.data))
            } else {
                dispatch(requestGetSellerSubscriptionList([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestGetSellerSubscriptionList(response) {
  return {
    type: SELLER_SUBSCRIPTION_DATA_LIST,
    seller_subscription_data_list: response,
  }
}

// ======================  end get Seller subscription list  ======================//

// ====================== set subscription value ======================//

export function setSellerSubscription(post) {
  return (dispatch) => {
    return dispatch(handleSetSellerSubscription(post))
  }
}

function handleSetSellerSubscription(post) {
  return dispatch => {
    return Axios.put(`api/set_artist_subscription/${post.seller_id}`,post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestSetSellerSubscription(response.data))
            } else {
                dispatch(requestSetSellerSubscription([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestSetSellerSubscription(response) {
  return {
    type: SET_SELLER_SUBSCRIPTION,
    set_seller_subscription: response,
  }
}

// ====================== end set subscription value ======================//


// ====================== update buyer common details ======================//

export function updateBuyerCommonDetails(post, user_id) {
  return (dispatch) => {
    return dispatch(handleUpdateBuyerCommonDetails(post, user_id))
  }
}

function handleUpdateBuyerCommonDetails(post, user_id) {
  return dispatch => {
    return Axios.put(`api/update_common_buyer_details/${user_id}`,post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestUpdateBuyerCommonDetails(response.data))
            } else {
                dispatch(requestUpdateBuyerCommonDetails([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestUpdateBuyerCommonDetails(response) {
  return {
    type: UPDATE_BUYER_COMMON_DETAILS,
    update_buyer_common_details: response,
  }
}

// ====================== end update buyer common details ======================//

// ====================== cancel subscription value ======================//

export function cancelSubscription(post) {
  return (dispatch) => {
    return dispatch(handleCancelSubscription(post))
  }
}

function handleCancelSubscription(post) {
  return dispatch => {
    return Axios.post(`api/cancel_subscription`,post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestCancelSubscription(response.data))
            } else {
                dispatch(requestCancelSubscription([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestCancelSubscription(response) {
  return {
    type: CANCEL_SUBSCRIPTION,
    cancel_subscription: response,
  }
}

export function emptyCancelMembership(){
  return {
    type: CANCEL_SUBSCRIPTION,
    cancel_subscription: []
  }
}

// ====================== end cancel subscription value ======================//

// ====================== cancel subscription value ======================//

export function AllSubscriptionList(post) {
  return (dispatch) => {
    return dispatch(handleALLSubscription(post))
  }
}

function handleALLSubscription(post) {
  return dispatch => {
    return Axios.post(`api/get_all_subscription`,post, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestAllSubscription(response.data))
            } else {
                dispatch(requestAllSubscription([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestAllSubscription(response) {
  return {
    type: ALL_SUBSCRIPTION_LIST,
    all_subscription_list: response,
  }
}

// ====================== end cancel subscription value ======================//

// ========================== get search page dynamic content ====================//


export function getAllSearchTerms(post) {
  return (dispatch) => {
      return dispatch(getSearchTermsData(post))
  }
}

function getSearchTermsData(post) {
  return dispatch => {
      return Axios.get('api/get_search_terms', post,{ baseURL: baseurl })
          .then(response => {
              dispatch(requestGetSearchTermsData(response.data))
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                      handleUnAuthorised();

                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestGetSearchTermsData(response) {
  return {
      type: SEARCH_TERMS,
      search_terms: response
  }
}

// ========================== end get search page dynamic content ====================//


// ========================== insert search term page ====================//


export function createSearchTermPage(post) {
  return (dispatch) => {
      return dispatch(insertSearchTermData(post))
  }
}

function insertSearchTermData(post) {
  return dispatch => {
      return Axios.post('api/create_search_term_page', post,{ baseURL: baseurl })
          .then(response => {
              dispatch(requestInsertSearchTermData(response.data))
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                      handleUnAuthorised();

                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestInsertSearchTermData(response) {
  return {
      type: CREATE_SEARCH_TERM_PAGE,
      create_search_term_page: response
  }
}

// ========================== end insert search term page ====================//


// ================== update search term page =================

export function updateSearchTermPage(post, search_id) {
  return (dispatch) => {
      return dispatch(updateSearchTermPageData(post, search_id))
  }
}

function updateSearchTermPageData(post, search_id) {
  return dispatch => {
      return Axios.put('api/update_search_term_page/'+search_id, post,{ baseURL: baseurl })
          .then(response => {
              dispatch(requestUpdateSearchTermPageData(response.data))
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                      handleUnAuthorised();

                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestUpdateSearchTermPageData(response) {
  return {
      type: UPDATE_SEARCH_TERM_PAGE,
      update_search_term_page: response
  }
}


// =================== end update search term page =============

// ========================== send job push notification ====================//

export function sendJobLivePushNotification(post) {
  return (dispatch) => {
      return dispatch(jobLivePushNotificationHandle(post))
  }
}

function jobLivePushNotificationHandle(post) {
  return dispatch => {
      return Axios.post('api/send_push_notification', post,{ baseURL: baseurl })
          .then(response => {
              dispatch(requestJobLivePushNotification(response.data))
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                      handleUnAuthorised();

                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestJobLivePushNotification(response) {
  return {
      type: JOB_LIVE_PUSH_NOTIFICATION,
      job_live_push_notification: response
  }
}

// ========================== end send job push notification ====================//

// ====================== get Buyer subscription list ======================//

export function getBuyerSubscriptionList() {
  return (dispatch) => {
    return dispatch(handleGetBuyerSubscriptionList())
  }
}

function handleGetBuyerSubscriptionList() {
  return dispatch => {
    return Axios.get(`api/get_customer_membership_role`, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestGetBuyerSubscriptionList(response.data))
            } else {
                dispatch(requestGetBuyerSubscriptionList([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestGetBuyerSubscriptionList(response) {
  return {
    type: BUYER_SUBSCRIPTION_DATA_LIST,
    buyer_subscription_data_list: response,
  }
}

// ======================  end get Buyer subscription list  ======================//

//  27/08/2023 / Aman kumar pandey/ for adding member in conversation

export function addParticipantInConversation(post) {
  return (dispatch) => {
      return dispatch(addaddParticipantInConversationApi(post))
  }
}

function addaddParticipantInConversationApi(post ) {
  return dispatch => {
      return Axios.post('api/add_participant', post,{ baseURL: baseurl })
          .then(response => {
              dispatch(requestAddaddParticipantInConversation(response.data))
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                      handleUnAuthorised();

                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestAddaddParticipantInConversation(response) {
  return {
      type: ADD_PARTICIPANT_IN_CONVERSATION,
      add_participant_in_conversation: response
  }
}


// ========================== End for adding member in conversation ========================== //

// ========================== Get User Notification Settings ==================== //

export function getNotificationSetting(post) {
  return (dispatch) => {
    return dispatch(userNotificationSettings(post))
  }
}

function userNotificationSettings(post) {
  return dispatch => {
    return Axios.post('api/get_notification_setting', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestUserNotificaitonSettings(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestUserNotificaitonSettings(response) {
  return {
    type: GET_USER_NOTIFICATION_SETTING,
    get_user_notification_setting: response
  }
}

// ========================== End  Get User Notification Settings ====================//

// ========================== Add User Notification Settings ====================//


export function setNotificationSetting(post) {
  return (dispatch) => {
    return dispatch(addNotificationSettings(post))
  }
}

function addNotificationSettings(post) {
  return dispatch => {
    return Axios.post('api/update_notification_setting', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestAddNotificaitonSettings(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAddNotificaitonSettings(response) {
  return {
    type: SET_USER_NOTIFICATION_SETTING,
    set_user_notification_setting: response
  }
}

// ========================== End Add User Notification Settings ====================//

// ========================== get user Email Notificaion ====================//

export function getEmailNotificationSetting(post) {
  return (dispatch) => {
    return dispatch(getEmailNotificationSettingFunction(post))
  }
}

function getEmailNotificationSettingFunction(post) {
  return dispatch => {
    return Axios.post('api/get_email_subscription', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetEmailNotificationSetting(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetEmailNotificationSetting(response) {
  return {
    type: GET_EMAIL_NOTIFICATION_SETTING,
    get_email_notification_setting: response
  }
}

// ========================== End get User Email Notification Settings ====================//

// ========================== Add User email Notification Settings ====================//

export function setEmailNotificationSetting(post) {
  return (dispatch) => {
    return dispatch(setEmailNotificationSettingFunction(post))
  }
}

function setEmailNotificationSettingFunction(post) {
  return dispatch => {
    return Axios.post('api/update_email_subscription', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestSetEmailNotificationSetting(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestSetEmailNotificationSetting(response) {
  return {
    type: SET_EMAIL_NOTIFICATION_SETTING,
    set_email_notification_setting: response
  }
}

// ========================== end Add User email Notification Settings ====================//

// ========================== Add User email Notification Settings ====================//

export function manageSellerBadge(post) {
  return (dispatch) => {
    return dispatch(manageSellerBadgeFunction(post))
  }
}

function manageSellerBadgeFunction(post) {
  return dispatch => {
    return Axios.post('api/add_badge', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestManageSellerBadge(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestManageSellerBadge(response) {
  return {
    type: MANAGE_SELLER_BADGE,
    manage_seller_badge: response
  }
}
// ========================== Gift card List ====================//

export function giftCardList(post) {
  return (dispatch) => {
    return dispatch(manageGiftCardList(post))
  }
}

function manageGiftCardList(post) {
  return dispatch => {
    return Axios.post('api/gift_card', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGiftCard(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGiftCard(response) {
  return {
    type: GIFT_CARD_LIST,
    gift_card_list: response
  }
}

// ========================== end Gift Card List ====================//
// ========================== Referral Users List ====================//

export function referralUserList(post) {
  return (dispatch) => {
    return dispatch(manageReferralUserList(post))
  }
}

function manageReferralUserList(post) {
  return dispatch => {
    return Axios.post('api/referral', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestReferralUserList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestReferralUserList(response) {
  return {
    type: REFERRAL_USERS_LIST,
    referral_users_list: response
  }
}

// ========================== end Add User email Notification Settings ====================//

//  ===================== Get invoice Detail ====================

export function getInvoiceDetailInvoiceCount(post) {
  return (dispatch) => {
    return dispatch(getInvoiceDetailFunction(post))
  }
}

function getInvoiceDetailFunction(post) {
  return dispatch => {
    return Axios.post('api/get_offers_list', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestgetInvoiceDetail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetInvoiceDetail(response) {
  return {
    type: GET_INVOICE_DETAIL_BY_INVOICE_COUNT,
    get_invoice_detail_by_invoice_count: response
  }
}
//  ===================== Get invoice Detail ====================

export function updateInvoiceDetail(post) {
  return (dispatch) => {
    return dispatch(updateInvoiceFunction(post))
  }
}

function updateInvoiceFunction(post) {
  return dispatch => {
    return Axios.post('api/edit_offer', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestupdateInvoiceDetail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestupdateInvoiceDetail(response) {
  return {
    type: UPDATE_INVOICE_DETAIL,
    update_invoice_detail: response
  }
}

//  ===================== PAYMENT TRACKING ====================

export function getPaymentTimeline(post) {
  return (dispatch) => {
    return dispatch(getPaymentTimelineFunction(post))
  }
}

function getPaymentTimelineFunction(post) {
  return dispatch => {
    return Axios.post('api/v2/payment_timeline',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getPaymentTimelineDetail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getPaymentTimelineDetail(response) {
  return {
    type: GET_PAYMENT_TIMELINE,
    get_payment_timeline: response
  }
}
//  ===================== ADD INVOICE ====================

export function addOrderInvoice(post) {
  return (dispatch) => {
    return dispatch(addOrderInvoiceFunction(post))
  }
}
// 
function addOrderInvoiceFunction(post) {
  return dispatch => {
    return Axios.post('api/make_an_offer',post, { baseURL: baseurl })
      .then(response => {
        dispatch(addOrderInvoiceFunctionDetail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function addOrderInvoiceFunctionDetail(response) {
  return {
    type: ADD_INVOICE,
    add_invoice: response
  }
}
//  ===================== ADD MULTIPLE INVOICE ====================

export function addOrderMultipleInvoice(post) {
  return (dispatch) => {
    return dispatch(addOrderMultipleInvoiceFunction(post))
  }
}
// 
function addOrderMultipleInvoiceFunction(post) {
  return dispatch => {
    return Axios.post('api/make_multiple_offers',post, { baseURL: baseurl })
      .then(response => {
        dispatch(addOrderMultipleInvoiceFunctionResponse(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function addOrderMultipleInvoiceFunctionResponse(response) {
  return {
    type: ADD_MULTIPLE_INVOICE,
    add_multiple_invoice: response
  }
}

//  ===================== Seller Commission Detail ====================

export function getSellerDetailForInvoice(post) {
  return (dispatch) => {
    return dispatch(getSellerDetailForInvoiceFunction(post))
  }
}
// 
function getSellerDetailForInvoiceFunction(post) {
  return dispatch => {
    return Axios.get( `api/get_order_for_invoice/`+ post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerDetailForInvoiceFunctionDetail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerDetailForInvoiceFunctionDetail(response) {
  return {
    type: SELLER_DETAIL_FOR_INVOICE,
    seller_detail_for_invoice : response
  }
}


//  ===================== Delete Offer ====================

export function deleteSellerOfferByAdmin(post) {
  return (dispatch) => {
    return dispatch(deleteSellerOfferByAdminFunction(post))
  }
}
// 
function deleteSellerOfferByAdminFunction(post) {
  return dispatch => {
    return Axios.post( `api/delete_offer`, post, { baseURL: baseurl })
      .then(response => {
        dispatch(deleteSellerOfferByAdminDetail(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function deleteSellerOfferByAdminDetail(response) {
  return {
    type: DELETE_OFFER,
    delete_offer : response
  }
}

// ========================== counter otp report ====================//

export function counterOtp(post) {
  return (dispatch) => {
    return dispatch(counterOtpFunction(post))
  }
}

function counterOtpFunction(post) {
  return dispatch => {
    return Axios.post('api/get_counter_otp_report', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestCounterOtp(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestCounterOtp(response) {
  return {
    type: COUNTER_OTP_REPORT,
    counter_otp_report: response
  }
}

// ========================== end counter otp report ====================//

// ========================== quite otp order report ====================//

export function quiteOtpOrderReport(post) {
  return (dispatch) => {
    return dispatch(quiteOtpOrderReportFunction(post))
  }
}

function quiteOtpOrderReportFunction(post) {
  return dispatch => {
    return Axios.post('api/quite_otp_orders_report', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestQuiteOtpOrderReport(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}


function requestQuiteOtpOrderReport(response) {
  return {
    type: QUITE_OTP_ORDER_REPORT,
    quite_otp_order_report: response
  }
}

// ========================== end quite otp order report ====================//

// ========================== add quite otp ====================//

export function addQuiteOtp(post) {
  return (dispatch) => {
    return dispatch(addQuiteOtpFunction(post))
  }
}

function addQuiteOtpFunction(post) {
  return dispatch => {
    return Axios.post('api/add_quite_otp', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestAddQuiteOtp(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestAddQuiteOtp(response) {
  return {
    type: ADD_QUITE_OTP,
    add_quite_otp: response
  }
}

// ========================== end remove quite otp ====================//

// ========================== remove quite otp ====================//

export function removeQuiteOtp(post) {
  return (dispatch) => {
    return dispatch(removeQuiteOtpFunction(post))
  }
}

function removeQuiteOtpFunction(post) {
  return dispatch => {
    return Axios.post('api/remove_quite_otp', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestRemoveQuiteOtp(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestRemoveQuiteOtp(response) {
  return {
    type: REMOVE_QUITE_OTP,
    remove_quite_otp: response
  }
}

// ========================== end remove quite otp ====================//

//  ===================== Seller Details ====================

export function getSellerAllDetail(post) {
  return (dispatch) => {
    return dispatch(getSellerDetailsFunction(post))
  }
}

function getSellerDetailsFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_details',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerDetailList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerDetailList(response) {
  return {
    type: SELLER_ALL_DETAIL,
    seller_all_detail: response
  }
}



//  ===================== Seller Order Detail Logs ====================

export function getSellerOrderDetailLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerOrderDetailFunction(post))
  }
}

function getSellerOrderDetailFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_order_detail',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerOrderDetailList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerOrderDetailList(response) {
  return {
    type: SELLER_ORDER_DETAIL_LOGS,
    seller_order_detail_logs: response
  }
}

  

//  ===================== Seller Payment Logs ====================

export function getSellerPaymentLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerPaymentLogsFunction(post))
  }
}

function getSellerPaymentLogsFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_payments',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerPaymentLogsList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerPaymentLogsList(response) {
  return {
    type: SELLER_PAYMENT_LOGS,
    seller_payment_logs: response
  }
}
 
//  ===================== Seller Transcation Logs ====================

export function getSellerTransactionLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerTranscationLogsFunction(post))
  }
}

function getSellerTranscationLogsFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_transaction_logs',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerTranscationLogsList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerTranscationLogsList(response) {
  return {
    type: SELLER_TRANSACTION_LOGS,
    seller_transaction_logs: response
  }
}
 
//  ===================== Seller Notification Logs ====================

export function getSellerNotificationLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerNotificationLogsFunction(post))
  }
}

function getSellerNotificationLogsFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_notification_logs',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerNotificationLogsList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerNotificationLogsList(response) {
  return {
    type: SELLER_NOTIFICATION_LOGS,
    seller_notification_logs: response
  }
}


//  ===================== Seller Experience Logs ====================

export function getSellerExperienceLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerExperienceLogsFunction(post))
  }
}

function getSellerExperienceLogsFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_experience_logs',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerExperienceLogsList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerExperienceLogsList(response) {
  return {
    type: SELLER_EXPERIENCE_LOGS,
    seller_experience_logs: response
  }
}


//  ===================== Seller Visibility Logs ====================

export function getSellerVisibilityLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerVisibilityFunction(post))
  }
}

function getSellerVisibilityFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_visibility_logs',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerVisibilityList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerVisibilityList(response) {
  return {
    type: SELLER_VISIBILITY_LOGS,
    seller_visibility_logs: response
  }
}

  

//  ===================== Seller CS Logs ====================

export function getSellerCSLogs(post) {
  return (dispatch) => {
    return dispatch(getSellerCSFunction(post))
  }
}

function getSellerCSFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_cs_logs',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerCSList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerCSList(response) {
  return {
    type: SELLER_CS_LOGS,
    seller_cs_logs: response
  }
}
  
//  ===================== Seller Order Count  ====================

export function getSellerOrderDetailCount(post) {
  return (dispatch) => {
    return dispatch(getSellerOrderDetailCountFunction(post))
  }
}

function getSellerOrderDetailCountFunction(post) {
  return dispatch => {
    return Axios.post('api/seller_order_count',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerOrderDetailCountList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerOrderDetailCountList(response) {
  return {
    type: SELLER_ORDER_COUNT,
    seller_order_count: response
  }
}
//  ===================== Seller MemberShip Logs  ====================

export function getSellerMemberShip(post) {
  return (dispatch) => {
    return dispatch(getSellerMemberShipFunction(post))
  }
}

function getSellerMemberShipFunction(post) {
  return dispatch => {
    return Axios.post('api/member_ship_logs',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerMemberShipFunctionList(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerMemberShipFunctionList(response) {
  return {
    type: MEMBER_SHIP_LOGS,
    member_ship_logs: response
  }
}
//  ===================== Seller Mixpanel Detail  ====================

export function getSellerMixpanelDetail(post) {
  return (dispatch) => {
    return dispatch(getSellerMixpanelDetailFunction(post))
  }
}

function getSellerMixpanelDetailFunction(post) {
  return dispatch => {
    return Axios.post('api/mixpanel_data',post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerMixpanelDetailData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();

          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerMixpanelDetailData(response) {
  return {
    type: MIXPANEL_SELLER_DATA,
    mixpanel_seller_data: response
  }
}

// [----------------------------------------------------]

export function getSellerListWithCity(post) {
  return (dispatch) => {
    return dispatch(getSellerListWithCityFunction(post))
  }
}

function getSellerListWithCityFunction(post) {
  return dispatch => {
    return Axios.post('api/get_seller_list_with_city', post, { baseURL: baseurl })
      .then(response => {
        dispatch(getSellerListWithCityData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function getSellerListWithCityData(response) {
  return {
    type: GET_SELLER_COUNT_WITH_STATE_BY_COUNTRY,
    get_seller_count_with_state_by_country: response
  }
}


//  ===================== Seller New Membership Update  ====================

export function assignSellerMembership(post, sellerId) {
  return (dispatch) => {
    return dispatch(assignSellerMembershipFunction(post, sellerId))
  }
}

function assignSellerMembershipFunction(post, sellerId) {
  return dispatch => {
    return Axios.put('api/assign_seller_membership/'+sellerId, post, { baseURL: baseurl })
      .then(response => {
        dispatch(assignSellerMembershipData(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function assignSellerMembershipData(response) {
  return {
    type: ASSIGN_SELLER_MEMBERSHIP,
    assign_seller_membership: response
  }
}

export function emptyAssignMembership(){
  return {
    type: ASSIGN_SELLER_MEMBERSHIP,
    assign_seller_membership: []
  }
}

// ====================== get Seller subscription list ======================//

export function getSellerSubscriptionListV2() {
  return (dispatch) => {
    return dispatch(handleGetSellerSubscriptionListV2())
  }
}

function handleGetSellerSubscriptionListV2() {
  return dispatch => {
    return Axios.get(`api/v2/get_subscription_role`, { baseURL: baseurl })
        .then(response => {            
            if (response.data !== "") {   
                dispatch(requestGetSellerSubscriptionListV2(response.data))
            } else {
                dispatch(requestGetSellerSubscriptionListV2([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
  }
}

function requestGetSellerSubscriptionListV2(response) {
  return {
    type: SELLER_SUBSCRIPTION_DATA_LIST,
    seller_subscription_data_list: response,
  }
}

// ======================  end get Seller subscription list  ======================//
// -------------------- Update Order Details Common api 
export function updateOrderDetails(order_id,post) {
  return (dispatch) => {
    // console.log({post,order_id})
    return dispatch(updateOrderDetailsData(order_id,post))
  }
}
function updateOrderDetailsData(order_id, post) {
  return dispatch => {
    return Axios.post(`api/update_order_details/${order_id}`, post,{ baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestupdateOrderDetailsData(response.data))
        }
        else {
          dispatch(requestupdateOrderDetailsData([]))
          // console.log(error);
        }
      })
  }
}

function requestupdateOrderDetailsData(response) {
  return {
    type: UPDATE_ORDER_DETAILS,
    update_order_details: response
  }
}
// -------------------- All Badges List -------------- 
export function getAllBadges(post) {
  return (dispatch) => {
    // console.log({post,order_id})
    return dispatch(getAllBadgesData(post))
  }
}
function getAllBadgesData( post) {
  return dispatch => {
    return Axios.post(`api/seller_badges_list`, post,{ baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestgetAllBadgesData(response.data))
        }
        else {
          dispatch(requestgetAllBadgesData([]))
          // console.log(error);
        }
      })
  }
}

function requestgetAllBadgesData(response) {
  return {
    type: ALL_BADGES_LIST,
    all_badges_list: response
  }
}
// -------------------- All Badges List -------------- 
export function checkNearByTopArtist(post) {
  return (dispatch) => {
    // console.log({post,order_id})
    return dispatch(checkNearByTopArtistData(post))
  }
}
function checkNearByTopArtistData( post) {
  return dispatch => {
    return Axios.post(`api/check_nearby_top_artist`, post,{ baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestcheckNearByTopArtistData(response.data))
        }
        else {
          dispatch(requestcheckNearByTopArtistData([]))
          // console.log(error);
        }
      })
  }
}

function requestcheckNearByTopArtistData(response) {
  return {
    type: CHECK_NEAR_BY_TOP_ARTIST,
    check_near_by_top_artist: response
  }
}
// -------------------- All Badges List -------------- 
export function commonSellerOtpUpdate(post,id) {
  return (dispatch) => {
    return dispatch(ccommonSellerOtpUpdateData(post,id))
  }
}

function ccommonSellerOtpUpdateData(post,id) {
  return dispatch => {
    return Axios.post(`api/update_seller_otp_details/${id}`, post,{ baseURL: baseurl })
      .then(response => {
        // console.log(response)
        if (response.data != "") {
          dispatch(requestCommonSellerOtpUpdateData(response.data))
        }
        else {
          dispatch(requestCommonSellerOtpUpdateData([]))
          // console.log(error);
        }
      })
  }
}

function requestCommonSellerOtpUpdateData(response) {
  return {
    type: COMMON_OTP_SELLER_DETAIL,
    common_otp_seller_detail: response
  }
}



// Start- get baa assistant conversation
export function getBaaAssistantConversation(post) {
  return (dispatch) => {
    return dispatch(getBaaConversation(post))
  }
}

function getBaaConversation(post) {
  return dispatch => {
    return Axios.get('api/get_baa_assistant_conversation/' + post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestGetBaaConversation(response.data))
        } else {
          dispatch(requestGetBaaConversation([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetBaaConversation(response) {
  return {
    type: BAA_ASSISTANT_CONVERSATION,
    baa_assistant_conversation: response
  }
}

// End- get baa assistant conversation


// Start- create baa assistant message
export function createBaaAssistantMessage(post) {
  return (dispatch) => {
    return dispatch(createBaaAssistantMessageAdmin(post))
  }
}

function createBaaAssistantMessageAdmin(post) {
  return dispatch => {
    return Axios.post('api/create_baa_assistant_message', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestCreateBaaAssistantMessage(response.data))
        } else {
          dispatch(requestCreateBaaAssistantMessage([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
function requestCreateBaaAssistantMessage(response) {
  return {
    type: CREATE_BAA_ASSISTANT_MESSAGE,
    create_baa_assistant_message: response
  }
}
// End- create baa assistant message

// =------------- Canvas otp report 
export function createCanvasOtpReport(post) {
  return (dispatch) => {
      return dispatch(requestCreateCanvasOtpReport(post))
  }
}

function requestCreateCanvasOtpReport(post) {
  return dispatch => {
    return Axios.post('api/canvas_otp_orders_report', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(responseCreateCanvasOtpReport(response.data))
        } else {
          dispatch(responseCreateCanvasOtpReport([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
function responseCreateCanvasOtpReport(response) {
  return {
    type: CANVAS_OTP_REPORT,
    canvas_otp_report: response
  }
}
// End- create baa assistant message
// =------------- Canvas otp report 
export function checkSellerCurrentMembership(post) {
  return (dispatch) => {
      return dispatch(requestCheckSellerCurrentMembership(post))
  }
}

function requestCheckSellerCurrentMembership(post) {
  return dispatch => {
    return Axios.post('api/check_seller_current_membership', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(responseCheckSellerCurrentMembership(response.data))
        } else {
          dispatch(responseCheckSellerCurrentMembership([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
function responseCheckSellerCurrentMembership(response) {
  return {
    type: CHECK_SELLER_CURRENT_MEMBERSHIP,
    check_seller_current_membership: response
  }
}

export function emptyCheckSellerCurrentMembership() {
  return dispatch => {
    dispatch( {
      type: CHECK_SELLER_CURRENT_MEMBERSHIP,
      check_seller_current_membership: []
    })
  }
}
// End- check seller current membership

export function getAlreadyPaidInformation(post,order_id) {
  return (dispatch) => {
      return dispatch(getAlreadyPaidInformationAction(post,order_id))
  }
}

function getAlreadyPaidInformationAction(post,order_id) {
  return dispatch => {
      return Axios.get('api/get_already_paid_response/'+order_id, { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestAlreadyPaidInformationAction(response.data))
              } else {
                  dispatch(requestAlreadyPaidInformationAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestAlreadyPaidInformationAction(response) {
  return {
      type: ALREADY_PAID_RESPONSE,
      already_paid_response: response
  }
}



export function getAlreadyPaidData(post) {
  return (dispatch) => {
      return dispatch(getAlreadyPaidDataAction(post))
  }
}

function getAlreadyPaidDataAction(post) {
  return dispatch => {
      return Axios.post('api/get_already_paid_reminder_response_data' ,post, { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestAlreadyPaidDataAction(response.data))
              } else {
                  dispatch(requestAlreadyPaidDataAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestAlreadyPaidDataAction(response) {
  return {
      type: ALREADY_PAID_RESPONSE_DATA,
      already_paid_response_data: response
  }
}



export function getAlreadyPaidReminderData() {
  return (dispatch) => {
      return dispatch(getAlreadyPaidReminderDataAction())
  }
}

function getAlreadyPaidReminderDataAction() {
  return dispatch => {
      return Axios.get('api/get_already_paid_reminder_data', { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestAlreadyPaidReminderDataActionAction(response.data))
              } else {
                  dispatch(requestAlreadyPaidReminderDataActionAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestAlreadyPaidReminderDataActionAction(response) {
  return {
      type: ALREADY_PAID_RESPONSE_DATA,
      already_paid_response_data: response
  }
}


export function getBuyCAnvasData() {
  return (dispatch) => {
      return dispatch(getBuyCanvasDataAction())
  }
}

function getBuyCanvasDataAction() {
  return dispatch => {
      return Axios.post('api/get_buy_canvas_data', { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestBuyCanvasDataAction(response.data))
              } else {
                  dispatch(requestBuyCanvasDataAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestBuyCanvasDataAction(response) {
  return {
      type: BUY_CANVAS_DATA,
      buy_canvas_data: response
  }
}

//  --------- MEMBERSHIP TRACKING
export function trackMembershipProgress(post) {
  return (dispatch) => {
      return dispatch(gettrackMembershipProgressAction(post))
  }
}

function gettrackMembershipProgressAction(post) {
  return dispatch => {
      return Axios.post('api/track_membership_level',post, { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requesttrackMembershipProgressAction(response.data))
              } else {
                  dispatch(requesttrackMembershipProgressAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requesttrackMembershipProgressAction(response) {
  return {
      type: MEMBER_SHIP_PROGRESS_TRACKING,
      member_ship_progress_tracking: response
  }
}

//  --------- MEMBERSHIP TRACKING
export function trackMemberShipTypeOrder(post) {
  return (dispatch) => {
      return dispatch(trackMemberShipTypeOrderAction(post))
  }
}

function trackMemberShipTypeOrderAction(post) {
  return dispatch => {
      return Axios.post('api/call_to_action_list',post, { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requesttrackMemberShipTypeOrderAction(response.data))
              } else {
                  dispatch(requesttrackMemberShipTypeOrderAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requesttrackMemberShipTypeOrderAction(response) {
  return {
      type: CTA_ORDER_LIST,
      cta_order_list: response
  }
}

export function emptyCTAList() {
  return dispatch => {
    dispatch( {
      type: CTA_ORDER_LIST,
      cta_order_list: []
    })
  }
}

//  --------- MEMBERSHIP TRACKING
export function sellerDashboardDetails(post) {
  return (dispatch) => {
      return dispatch(sellerDashboardDetailAction(post))
  }
}

function sellerDashboardDetailAction(post) {
  return dispatch => {
      return Axios.post('api/get-dashboard-kpi-count',post, { baseURL: baseurl })
          .then(response => {    
              if (response.data !== "") {               
                  dispatch(requestSellerDashboardDetailAction(response.data))
              } else {
                  dispatch(requestSellerDashboardDetailAction([]))
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                  } else {
                      console.log(error);
                  }
              }
          });
  }
}

function requestSellerDashboardDetailAction(response) {
  return {
      type: SELLER_DASHBOARD_DETAILS,
      seller_dashboard_details: response
  }
}

// check stripe status start

export function getStripeStatus(post) {
  return (dispatch) => {
      return dispatch(checkStripeStatus(post))
  }
}

function checkStripeStatus(post) {
  return dispatch => {
      return Axios.post('api/check_stripe_status', post, { baseURL: baseurl })
      .then(response => {
          if (response.data !== "") {
              dispatch(storeStripeStatus(response.data))
          } else {
              dispatch(storeStripeStatus([]))
          }
      })
      .catch(error => {
          if (error.response) {
              if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
              } else {
                  console.log(error);
              }
          }
      });
  }
}

function storeStripeStatus(response) {
  return {
      type: CHECK_STRIPE_STATUS,
      check_stripe_status: response
  }
}

export function emptyCheckStripe() {
  return dispatch => {
    dispatch( {
      type: CHECK_STRIPE_STATUS,
      check_stripe_status: []
    })
  }
}

// check stripe status end

//  approve order
export function approveHoldOrder(post) {
  return (dispatch) => {
      return dispatch(approveHoldOrderApiCall(post))
  }
}

function approveHoldOrderApiCall(post) {
  return dispatch => {
      return Axios.post('api/approve_order', post, { baseURL: baseurl })
      .then(response => {
          if (response.data !== "") {
              dispatch(approveHoldOrderApiCallResponse(response.data))
          } else {
              dispatch(approveHoldOrderApiCallResponse([]))
          }
      })
      .catch(error => {
          if (error.response) {
              if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
              } else {
                  console.log(error);
              }
          }
      });
  }
}

function approveHoldOrderApiCallResponse(response) {
  return {
      type: APPROVE_HOLD_ORDER,
      approve_hold_order: response
  }
}


// BAA OpenAI V1
export function baaOpenAiV1(post) {
  return (dispatch) => {
      return dispatch(baaOpenAiV1Req(post))
  }
}

function baaOpenAiV1Req(post) {
  return dispatch => {
      return Axios.post('api/baa_openai_v1', post, { baseURL: baseurl })
      .then(response => {
          if (response.data !== "") {
              dispatch(baaOpenAiV1Res(response.data))
          } else {
              dispatch(baaOpenAiV1Res([]))
          }
      })
      .catch(error => {
          if (error.response) {
              if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
              } else {
                  console.log(error);
              }
          }
      });
  }
}

function baaOpenAiV1Res(response) {
  return {
      type: BAA_OPENAI_V1,
      baa_openai_v1: response
  }
}

// End- BAA OpenAI V1


// START- AWS Bedrock 
export function awsBedrockV1(post) {
  return (dispatch) => {
      return dispatch(awsBedrockV1Req(post))
  }
}

function awsBedrockV1Req(post) {
  return dispatch => {
      return Axios.post('api/aws_bedrock_v1', post, { baseURL: baseurl })
      .then(response => {
          if (response.data !== "") {
              dispatch(awsBedrockV1Res(response.data))
          } else {
              dispatch(awsBedrockV1Res([]))
          }
      })
      .catch(error => {
          if (error.response) {
              if (error.response.status === 403) {
                  handleUnAuthorised();
                  // dispatch(push('/'));
              } else {
                  console.log(error);
              }
          }
      });
  }
}

function awsBedrockV1Res(response) {
  return {
      type: AWS_BEDROCK_V1,
      aws_bedrock_v1: response
  }
}

// End- AWS Bedrock 


// Start- create baa copilot message
export function createBaaCopilotMessage(post) {
  return (dispatch) => {
    return dispatch(createBaaCopilotMessageAdmin(post))
  }
}

function createBaaCopilotMessageAdmin(post) {
  return dispatch => {
    return Axios.post('api/create_copilot_message', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestCreateBaaCopilotMessage(response.data))
        } else {
          dispatch(requestCreateBaaCopilotMessage([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
function requestCreateBaaCopilotMessage(response) {
  return {
    type: CREATE_BAA_COPILOT_MESSAGE,
    create_baa_copilot_message: response
  }
}
// End- create baa copilot message


// Start- get baa copilot message
export function getBaaCopilotMessages(post) {
  return (dispatch) => {
    return dispatch(getBaaCopilotMessagesAdmin(post))
  }
}

function getBaaCopilotMessagesAdmin(post) {
  return dispatch => {
    return Axios.post('api/get_baa_copilot_messages', post, { baseURL: baseurl })
      .then(response => {
        if (response.data !== "") {
          dispatch(requestGetBaaCopilotMessages(response.data))
        } else {
          dispatch(requestGetBaaCopilotMessages([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
function requestGetBaaCopilotMessages(response) {
  return {
    type: GET_BAA_COPILOT_MESSAGES,
    get_baa_copilot_messages: response
  }
}
// End- get baa copilot message


// Start- copilot buyer associated order analysis
export function buyerOrdersCopilotAnalysis(post) {
  return (dispatch) => {
    return dispatch(buyerOrdersAdminCopilotAnalysis(post))
  }
}

function buyerOrdersAdminCopilotAnalysis(post) {
  return dispatch => {
    return Axios.post('api/buyer_orders_copilot_analysis', post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestOrdersCopilotAnalysis(response.data))
        } else {
          dispatch(requestOrdersCopilotAnalysis([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}
function requestOrdersCopilotAnalysis(response) {
  return {
    type: BUYER_ORDERS_COPILOT_ANALYSIS,
    buyer_orders_copilot_analysis: response
  }
}
// End- copilot buyer associated order analysis

//  update jpb pdf with conversation
export function updateOrderConversation(post) {
  return (dispatch) => {
    return dispatch(updateOrderConversationFunction(post))
  }
}

function updateOrderConversationFunction(post) {

  return dispatch => {
    return Axios.post('api/update_conversation_url' ,post, { baseURL: baseurl })
      .then(response => {

        if (response.data !== "") {
          dispatch(requestUpdateOrderConversationFunction(response.data))
        } else {
          dispatch(requestUpdateOrderConversationFunction([]))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
            // dispatch(push('/'));
          } else {
            console.log(error);
          }
        }
      });
  }
}


function requestUpdateOrderConversationFunction(response) {
  return {
    type: UPDATE_ORDER_CONVERSATION,
    update_order_conversation: response
  }
}


export function removeQuiteOtpVersion2(post) {
  return (dispatch) => {
    return dispatch(removeQuiteOtpVersion2Function(post))
  }
}

function removeQuiteOtpVersion2Function(post) {
  return dispatch => {
    return Axios.post('api/v2/remove_quite_otp', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestRemoveQuiteOtpVersion2Function(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestRemoveQuiteOtpVersion2Function(response) {
  return {
    type: REMOVE_QUITE_OTP_V2,
    remove_quite_otp_v2: response
  }
}

// ========================== end remove quite otp ====================//

export function getAllBuyerOrder(post) {
  return (dispatch) => {
    return dispatch(getAllBuyerOrderFunction(post))
  }
}

function getAllBuyerOrderFunction(post) {
  return dispatch => {
    return Axios.post('api/get_all_buyer_order', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestgetAllBuyerOrderFunction(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestgetAllBuyerOrderFunction(response) {
  return {
    type: BUYER_ORDER_CONVERSATION,
    buyer_order_conversation: response
  }
}


export function getAllBuyerOrderByUserId(post) {
  return (dispatch) => {
    return dispatch(getAllBuyerOrderByUserIdFunction(post))
  }
}

function getAllBuyerOrderByUserIdFunction(post) {
  return dispatch => {
    return Axios.post('api/get_all_orders_by_user_id', post, { baseURL: baseurl })
      .then(response => {
        dispatch(requestGetAllBuyerOrderByUserIdFunction(response.data))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            handleUnAuthorised();
          } else {
            console.log(error);
          }
        }
      });
  }
}

function requestGetAllBuyerOrderByUserIdFunction(response) {
  return {
    type: BUYER_ORDER_CONVERSATION_BY_USER_ID,
    buyer_order_conversation_by_user_id: response
  }
}

